import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "../css/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const CarrucelAndroid = () => {
  const handleOnDragStart = (e) => e.preventDefault();

  const items = [
    <div className="item" key="1">
      <div className="row">
        <div className="col-12 col-xl-7 col-lg-7 col-sm-7">
            <div className="row contTxtCarrucel">
                <div className="col-12 contPrimerTxx">
                    <span>-Abrí la web de <span className="txtNaranja">ZH Inversiones </span>
                    desde el navegador Chrome.
                    </span>
                </div>
                <div className="col-12 ">
                    <span>-Desplegá el <span className="txtNaranja">MENÚ</span> de la parte superior derecha de la pantalla</span>
                </div>
            </div>
        </div>
        <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contImgImgIntruc">
          <img
            src={require("../assets/Android/Android 01.png")}
            alt="imagen 1"
            className="imgInstruc"
            onDragStart={handleOnDragStart}
          />
        </div>
      </div>
    </div>,
    <div className="item" key="2">
      <div className="row">
        <div className="col-12 col-xl-7 col-lg-7 col-sm-7">
            <div className="row contTxtCarrucel">
                <div className="col-12 contPrimerTxx">
                    <span>-Luego presionamos el botón que dice <span className="txtNaranja">DESCARGAR</span>
                    </span>
                </div>
                <div className="col-12">
                    <span className="txtExplicac">(si no aparece la opción de descargar al final te explicamos cómo hacerlo)</span>
                </div>
            </div>
        </div>
        <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contImgImgIntruc">
          <img
            src={require("../assets/Android/Android 02.png")}
            alt="imagen 1"
            className="imgInstruc"
            onDragStart={handleOnDragStart}
          />
        </div>
      </div>
    </div>,
    <div className="item" key="3">
      <div className="row">
        <div className="col-12 col-xl-7 col-lg-7 col-sm-7">
            <div className="row contTxtCarrucelTres">
                <div className="col-12">
                    <span>-Presionamos <span className="txtNaranja">INSTALAR</span>
                    </span>
                </div>
            </div>
        </div>
        <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contImgImgIntruc">
          <img
            src={require("../assets/Android/Android 04.png")}
            alt="imagen 1"
            className="imgInstrucCua"
            onDragStart={handleOnDragStart}
          />
        </div>
      </div>
    </div>,
    <div className="item" key="4">
      <div className="row">
        <div className="col-12 col-xl-7 col-lg-7 col-sm-7">
            <div className="row contTxtCarrucel">
                <div className="col-12">
                    <span>-Luego de unos segundos se agregará en nuestra pantalla.
                    </span>
                </div>
            </div>
        </div>
        <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contImgImgIntruc">
          <img
            src={require("../assets/Android/Android 05.png")}
            alt="imagen 1"
            className="imgInstruc"
            onDragStart={handleOnDragStart}
          />
        </div>
      </div>
    </div>,
    <div className="item" key="5">
      <div className="row">
        <div className="col-12 col-xl-7 col-lg-7 col-sm-7">
            <div className="row contTxtCarrucel">
                <div className="col-12 contPrimerTxx">
                    <span className="txtBold">- Si no te apareció el botón <span className="txtNaranja">DERSCARGAR </span>
                    tenes que hacer lo siguiente:
                    </span>
                </div>
                <div className="col-12 ">
                    <span>- Presioná<span className="txtNaranja"> tres puntos</span> que se encuentra al lado de la dirección del sitio web</span>
                </div>
            </div>
        </div>
        <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contImgImgIntruc">
          <img
            src={require("../assets/Android/Android 06.png")}
            alt="imagen 1"
            className="imgInstruc"
            onDragStart={handleOnDragStart}
          />
        </div>
      </div>
      </div>,
    <div className="item" key="6">
    <div className="row">
    <div className="col-12 col-xl-7 col-lg-7 col-sm-7">
            <div className="row contTxtCarrucelTres">
                <div className="col-12">
                    <span>- Y a continuación <span className="txtNaranja">INSTALAR APLICACIÓN</span>
                    </span>
                </div>
            </div>
        </div>
      <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contImgImgIntruc">
        <img
          src={require("../assets/Android/Android 03.png")}
          alt="imagen 1"
          className="imgInstruc"
          onDragStart={handleOnDragStart}
        />
      </div>
    </div>
  </div>,
  <div className="item" key="7">
  <div className="row">
  <div className="col-12 col-xl-7 col-lg-7 col-sm-7">
            <div className="row contTxtCarrucelTres">
                <div className="col-12">
                    <span>-Presionamos <span className="txtNaranja">INSTALAR</span>
                    </span>
                </div>
            </div>
        </div>
    <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contImgImgIntruc">
      <img
        src={require("../assets/Android/Android 04.png")}
        alt="imagen 1"
        className="imgInstrucCua"
        onDragStart={handleOnDragStart}
      />
    </div>
  </div>
</div>,
<div className="item" key="8">
      <div className="row">
        <div className="col-12 col-xl-7 col-lg-7 col-sm-7">
            <div className="row contTxtCarrucel">
                <div className="col-12">
                    <span>-Luego de unos segundos se agregará en nuestra pantalla.
                    </span>
                </div>
            </div>
        </div>
        <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contImgImgIntruc">
          <img
            src={require("../assets/Android/Android 05.png")}
            alt="imagen 1"
            className="imgInstruc"
            onDragStart={handleOnDragStart}
          />
        </div>
      </div>
    </div>,
  ];

  const responsive = {
    0: { items: 1 },
    1024: { items: 1 },
  };

  return (
    <AliceCarousel
      mouseTracking
      items={items}
      responsive={responsive}
      autoPlay
      autoPlayInterval={30000000}
      showSlideInfo={false}
      slideToIndex={0}
      dotsDisabled={false}
      buttonsDisabled={false}
      infinite={true}
      renderPrevButton={({ isDisabled }) => (
        <button className="buttonPrevCel" disabled={isDisabled}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      )}
      renderNextButton={({ isDisabled }) => (
        <button className="buttonNextCelAnd" disabled={isDisabled}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      )}
    />
  );
};

export default CarrucelAndroid;
