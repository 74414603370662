import { useEffect } from "react"
import axios from "axios";


const URL_API_ZUCOM = "https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerSeriesCotizacionZucom"

const ApiCotizZucom= () => {

   
    const apiZucom = async (e) => {
        try {
            await axios.get(URL_API_ZUCOM)
                .then(res => res.data)
                .then(res => mostrarDatoos(res))
        } catch (error) {

        }
    }
    useEffect(() => {
        apiZucom();
    },[]);
    const mostrarDatoos = (data) => {
        
        let valorMca = "";
        let valMca = "";
        let valorZucom = ""
        let valZucom = ""
        if(data.status === "true"){
            valorZucom += `${data.message.Zucom}`
            valZucom = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(valorZucom);
            valorMca += `${data.message.PMTARQ}`;
            valMca = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(valorMca);
        }
        
        document.getElementById('valZucom').innerHTML = (`${valZucom}`);
        document.getElementById('valMca').innerHTML = (`${valMca}`)
    }
}

export default ApiCotizZucom