import React, { useEffect } from 'react';

const ArtiBotComponent = () => {
  useEffect(() => {
    if (!window.artibotApi) {
      window.artibotApi = {
        l: [],
        t: [],
        on: function () {
          this.l.push(arguments);
        },
        trigger: function () {
          this.t.push(arguments);
        },
      };
    }
    let a = false;
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = 'https://app.artibot.ai/loader.js';
    document.getElementsByTagName('head').item(0).appendChild(script);
    script.onreadystatechange = script.onload = function () {
      if (!(a || (this.readyState && this.readyState !== 'loaded' && this.readyState !== 'complete'))) {
        new window.ArtiBot({ i: 'd749e154-9f03-4a61-9918-167dd4954b8a' });
        a = true;
      }
    };
  }, []);

  return (
    <div>
      {/* Aquí puedes agregar cualquier contenido relacionado con ArtiBot si es necesario */}
    </div>
  );
};

export default ArtiBotComponent;
