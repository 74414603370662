import React, {useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import "../css/style.css";
import "../css/login.css";
import 'sweetalert2/src/sweetalert2.scss';
import axios from 'axios';
import Img from '../assets/Dark_background_1920x1080.png';
import ImgLogo from "../assets/logo-zuppa-hermanos-acopio.png";
import Countdown from '../components/Contador';

const backgroundImageMain = {
    backgroundImage: `url(${Img})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: "100vh",

}

const RECUP_CONTRASENA = "https://www.zuppahermanos.com.ar/virtual/api/auth.php"


const RecuperarContraseña = () => {

    const [email, setEmail] = useState(null)
    const [success, setSuccess] = useState(false)
    const [danger, setDanger] = useState(false)
    const [primerEnv, setPrimerEnv] = useState(true)
    const [segundoEnv, setSegundoEnv] = useState(false)
    const [contador, setContador] = useState(false)
    const [nuevMen, setNuevMen] = useState(false)


    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (event) => {
            // Prevent the mini-infobar from appearing on mobile.
            event.preventDefault();
            // Stash the event so it can be triggered later.
            window.deferredPrompt = event;
            // Remove the 'hidden' class from the install button container.
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(RECUP_CONTRASENA, JSON.stringify({
                accion: "recuperar_pass_cliente",
                emailCli: email
            }), {
                headers: {
                    'Content-type': 'text/html; charset=UTF-8'
                }
            }).then(res => {
                if (res.data.status === "true") {
                    if (res.data.id_message === "200") {
                        setContador(true)
                        setNuevMen(false)
                        setSuccess(true)
                        setDanger(false)
                        setPrimerEnv(false)
                        setSegundoEnv(true)
                        let msg = res.data.message
                        setTimeout(() => {
                            document.getElementById('success').innerHTML = msg
                        }, 500);
                        setTimeout(() => {
                            setSuccess(false)
                            setNuevMen(true)
                            setPrimerEnv(true)
                            setContador(false)
                            setSegundoEnv(false)
                        }, 60000);
                    }
                } else if (res.data.status === "false") {
                    if (res.data.id_message === "400") {
                        let mensajeD = res.data.message
                        setDanger(true)
                        setSuccess(false)
                        setTimeout(() => {
                            document.getElementById('danger').innerHTML = mensajeD
                        }, 500);
                    }
                }
            })
        } catch (error) {

        }
    }
    return (
        <main style={backgroundImageMain}>
            <div className='container-fluid conteRc'>
                <div className='row contBoxRc'>
                    <div className='col-xl-4 col-lg-5 col-md-8 col-sm-10 col-11 boxRc'>
                        <div className='row '>
                            <div className='col-12 contenedorImgLogin'>
                                <img src={ImgLogo} className="img-logoRc img-fluid" alt='logo acopio' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <form className='form' onSubmit={handleSubmit}>
                                    <div className='contTitleRc'>
                                        <span className='spanTituloRc'>Recuperar Contraseña</span>
                                    </div>
                                    <div className='form__container'>

                                        <div className='form__groupDos'>
                                            <input
                                                type="email"
                                                className="form__input"
                                                placeholder=" "
                                                value={email}
                                                id="email"
                                                name='email'
                                                onChange={(e) => setEmail(e.target.value)} />

                                            <label htmlFor="email" className='form__label'>Email</label>
                                            <span className='form__line'></span>
                                        </div>
                                        {contador && <Countdown />}
                                        {nuevMen && <span className='txtTimer'>Puedes solicitar un nuevo codigo</span>}
                                        {
                                            success && <div>
                                                <div className='contSuccess'>
                                                    <span className='txtSuccess' id='success'> </span>
                                                </div>
                                                <span className='txtSuccess' id='countdown'></span>
                                            </div>
                                        }


                                        {
                                            danger && <div>
                                                <div className='contDanger'>
                                                    <span className='txtDanger' id='danger'> </span>
                                                </div>
                                            </div>
                                        }



                                        {primerEnv && <div className='row justify-content-center'>
                                            <div className='col-6 conVolLog'>
                                                <FontAwesomeIcon icon={faArrowLeft} className="flechaVolLog" />
                                                <a className='txtVolLog' href='./' >Volver al Inicio</a>
                                            </div>
                                            <div className='col-6 contBotRc'>
                                                <button className='btn btnRc'>Enviar</button>
                                            </div>
                                        </div>}
                                        {segundoEnv && <div className='row justify-content-center'>
                                            <div className='col-6 conVolLog'>
                                                <FontAwesomeIcon icon={faArrowLeft} className="flechaVolLog" />
                                                <a className='txtVolLog' href='/login' >Volver al Inicio</a>
                                            </div>
                                            <div className='col-6 contBotRc'>
                                                <button className='btn btnRc' disabled>Enviar</button>
                                            </div>
                                        </div>}
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main >
    )
}

export default RecuperarContraseña