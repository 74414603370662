import React from "react";

const TablaIndec = ({ columns, datosCotiz }) => {
  return (
    <div className="row justify-content-center contenedorTablaAnual">
      <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 col-12 table-responsive contTabla">
        <div className="table-wrapper">
          <table
            className="table table-dark table-hover table-responsive table-striped"
            id="grilla"
          >
            <thead>
              <tr>
                {columns &&
                  columns.map((column) => (
                    <th
                      style={{ color: "#fff", backgroundColor: "#E85217" }}
                      key={column}
                    >
                      {column}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody
              style={{ backgroundColor: " #333333" }}
              name="datosCompra"
              id="datosCompra"
            >
              {Array.isArray(datosCotiz) && datosCotiz.length > 0 ? (
                datosCotiz.map((datos) => (
                  <tr key={datos.mes}>
                    <td className="text-left">{datos.mes}</td>
                    <td className="text-center">{datos.ipc_mensual}</td>
                    <td className="text-center">
                      {datos.ipc_acumulado}
                    </td>
                    <td className="text-center">{datos.ipc_interanual}</td>
                  </tr>
                ))
              ) : (
                // Si datosCotiz no es un array, puedes mostrar un mensaje o cualquier otro contenido
                <tr>
                  <td colSpan={4}>No hay Indice para mostrar en este año</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TablaIndec;
