import React, { useEffect, useState, useRef, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Img from "../assets/Dark_background_1920x1080.png";
import ImgLogo from "../assets/logo-zuppa-hermanos-acopio.png";
import ImgLogoCarga from "../assets/logo_con_trazo.png";
import "../css/style.css";
import "../css/login.css";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import AuthContext from "../context/AuthProvider";
import axios from "axios";

const LOGIN_URL = "https://www.zuppahermanos.com.ar/virtual/api/auth.php";

const backgroundImageMain = {
  backgroundImage: `url(${Img})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100vh",
};

const Login = () => {
  const { setAuth } = useContext(AuthContext);
  const dniRef = useRef();

  const [dni, setDni] = useState(null);
  const [password, setPassword] = useState("");
  const [isReadyForCarga, setIsReadyForCarga] = useState(true);
  const [seconds, setSeconds] = useState(30);
  const [isActive, setIsActive] = useState(false);
  const [ocultarBrn, setOcultarBtn] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reloadBtn, setReloadBtn] = useState(false);
  const [textError, setTextError] = useState(
    "Porfavor revise sus conexión y vuelva a intetarlo mas tarde"
  );

  useEffect(() => {
    let interval = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false);
    }

    if (seconds === 0) {
      setReloadBtn(true);
      setIsSubmitting(false);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(() => {
    dniRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsActive((prevIsActive) => !prevIsActive);
    setOcultarBtn(false);
    setIsSubmitting(true);

    try {
      const res = await axios
        .post(
          LOGIN_URL,
          JSON.stringify({
            accion: "login_cliente",
            dni: dni,
            password: password,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.status === "true") {
            localStorage.setItem("userDataAcopio", dni);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("cod_zh", res.data.message.cod_zh);
            localStorage.setItem("cod_sinc", res.data.message.cod_sinc);
            localStorage.setItem("nombre", res.data.message.nom_ape);
            localStorage.setItem("nombre_sol", res.data.message.nombre);
            localStorage.setItem("apellido", res.data.message.apellido);
            localStorage.setItem(
              "tokenFirebase",
              JSON.stringify(res.data.message.token_firebase)
            );
            localStorage.setItem("claveUser", res.data.message.clink);
            window.location.href = "/";
          } else if (res.data.status === "false") {
            if (res.data.id_message === "403") {
              Swal.fire({
                title: "Atención! ",
                titleColor: "#E85217",
                width: "20em",
                color: "#fff",
                background: "#2B2B2B",
                text: `${res.data.message}`,
                showCancelButton: true,
                confirmButtonColor: "#E85217",
                cancelButtonColor: "#666666",
                confirmButtonText: "Si!",
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.href = "./Registro";
                }
              });
            } else {
              setSeconds(0);
              Swal.fire({
                title: "Atención! ",
                width: "20em",
                color: "#fff",
                background: "#2B2B2B",
                titleColor: "#E85217",
                text: `${res.data.message}`,
                confirmButtonColor: "#E85217",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.href = "./login";
                }
              });
              setTextError(res.data.message);
            }
          }
        });
      const token = res?.data?.token;
      setAuth({ dni, token });
      setDni("");
      setPassword("");
    } catch (err) {
      if (!err?.res) {
        setSeconds(0);
        setReloadBtn(true);
        setIsSubmitting(false);
      }
    }
  };
  const recargarWeb = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  const componentDidMount = () => {
    if (localStorage.getItem("token")) {
      window.location.href = "./";
    }
  };
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
    });
  }, []);

  componentDidMount();

  useEffect(() => {
    setTimeout(() => {
      setIsReadyForCarga(false);
    }, 500);
  });

  return (
    <div className="divContenedor">
      {isReadyForCarga && (
        <div className="contHijo">
          <div className="divContenedorHijo">
            <div className="contenedor-loader">
              <div>
                <img src={ImgLogoCarga} className="loader1" alt="logo carga" />
              </div>
            </div>
            <div className="conTxt">
              <span className="txtLoad">ZH Inversiones</span>
            </div>
          </div>
        </div>
      )}
      <main style={backgroundImageMain}>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-8 col-sm-10 col-11 boxLogin">
              <div className="row ">
                <div className="col-12 contenedorImgLogin">
                  <img
                    src={ImgLogo}
                    className="img-logoLogin img-fluid"
                    alt="logo acopio"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <form className="form" onSubmit={handleSubmit}>
                    <h2 className="form__title">Bienvenido!</h2>

                    <div className="form__container">
                      <div className="form__group">
                        <input
                          type="text"
                          className="form__input"
                          placeholder=" "
                          value={dni}
                          ref={dniRef}
                          id="dni"
                          name="dni"
                          onChange={(e) => setDni(e.target.value)}
                        />

                        <label htmlFor="dni" className="form__label">
                          DNI
                        </label>
                        <span className="form__line"></span>
                      </div>
                      <div className="form__groupDos">
                        <input
                          type="password"
                          className="form__input"
                          placeholder=" "
                          value={password}
                          id="password"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />

                        <label htmlFor="password" className="form__label">
                          Contraseña
                        </label>
                        <span className="form__line"></span>
                      </div>

                      {reloadBtn && (
                        <div className="row">
                          <div className="col-12" style={{ color: "#fff" }}>
                            {" "}
                            <span>Parece que algo salio mal</span>
                            <br></br>
                            <span style={{ fontSize: "11.5px" }}>
                              {textError}
                            </span>
                          </div>
                          <div className="col-6"> </div>
                          <div className="col-6 contBotInicio">
                            <button
                              className="btn btnLogin"
                              onClick={recargarWeb}
                            >
                              Reintentar
                            </button>
                          </div>
                        </div>
                      )}
                      {isSubmitting && (
                        <div
                          id="divMensajeAguarde"
                          style={{
                            marginTop: "5px",
                            paddingBottom: "10px",
                            display: "grid",
                            placeItems: "center",
                          }}
                          className="hidden"
                        >
                          <span
                            style={{
                              color: "#fff",
                              margin: "0 0 10px 0",
                              fontSize: "15px",
                            }}
                          >
                            Logueo en proceso {seconds}
                          </span>
                          <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      )}
                      {ocultarBrn && (
                        <>
                          <div className="row contRecCont">
                            <div className="col-12">
                              <a
                                a
                                className="btnRecCont"
                                href="./RecuperarContrasena"
                              >
                                Recuperar Contraseña
                              </a>
                            </div>
                          </div>
                          <div className="row contenedorBtnsIni">
                            <div className="col-6">
                              {" "}
                              <a className="botonRegisAlerta" href="./registro">
                                ¿No Tienes Cuenta? Regístrate Aquí
                              </a>
                            </div>
                            <div className="col-6 contBotInicio">
                              <button className="btn btnLogin">Iniciar</button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Login;
