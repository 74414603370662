import React, { useEffect, useState } from "react";
import "../css/style.css";
import "../css/login.css";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import axios from "axios";
import Img from "../assets/Dark_background_1920x1080.png";
import ImgLogo from "../assets/logo-zuppa-hermanos-acopio.png";
import FormInput from "../components/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const backgroundImageMain = {
  backgroundImage: `url(${Img})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100vh",
};

const RECUP_CONTRASENA =
  "https://www.zuppahermanos.com.ar/virtual/api/auth.php";

const ResetearContraseña = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ocultarBrn, setOcultarBtn] = useState(true);
  const [seconds, setSeconds] = useState(30);
  const [reloadBtn, setReloadBtn] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [textError, setTextError] = useState("Porfavor revise sus conexión y vuelva a intetarlo mas tarde")
  const [dni, setDni] = useState(null);
  const [password, setPassword] = useState("");
  const [values, setValues] = useState({
    accion: "cambiar_pass_cliente",
    dniCli: "",
    claveUser: "",
    passNueva: "", // validar
  });
  const [showInputs, setShowInputs] = useState(true);

  useEffect(() => {
    const dniCliExists = localStorage.getItem("userDataAcopio");
    const claveUserExists = localStorage.getItem("claveUser");

    if (dniCliExists && claveUserExists) {
      // Si existen datos en el Local Storage, ocultar los inputs con id: 1 y id: 2
      setShowInputs(false);
      // Actualizar los valores de los inputs con los datos del Local Storage
      setDni(dniCliExists);
      setValues({
        ...values,
        dniCli: dniCliExists,
        claveUser: claveUserExists,
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
    });
  }, []);

  const handleSubmitCam = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Establecer el estado de isSubmitting a true antes de enviar el formulario
    setOcultarBtn(false); // Establecer el estado de ocultarBtn en false antes de enviar el formulario
    setIsActive((prevIsActive) => !prevIsActive);
    try {
      await axios
        .post(
          RECUP_CONTRASENA,
          JSON.stringify({
            accion: "login_cliente",
            dni: dni,
            password: password,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          if (res.data.status === "true") {
            try {
              axios
                .post(
                  RECUP_CONTRASENA,
                  JSON.stringify({
                    ...values,
                  }),
                  {
                    headers: {
                      "Content-type": "text/html; charset=UTF-8",
                    },
                  }
                )
                .then((res) => {
                  if (res.data.status === "true") {
                    Swal.fire({
                      width: "20em",
                      color: "#fff",
                      background: "#2B2B2B",
                      position: "center",
                      icon: "success",
                      title: res.data.message,
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    setTimeout(() => {
                      window.location.href = "./";
                    }, 1650);
                  }else {
                    Swal.fire({
                      width: "20em",
                      color: "#fff",
                      background: "#2B2B2B",
                      title: "Atención! ",
                      titleColor: "#E85217",
                      text: `${res.data.message}`,
                      confirmButtonColor: "#E85217",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setSeconds(0);
                        setTextError(res.data.message);
                      }
                    });
                    setTextError(res.data.message);
                  }
                  if (res.data.status === "false") {
                    Swal.fire({
                      width: "20em",
                      color: "#fff",
                      background: "#2B2B2B",
                      position: "center",
                      icon: "error",
                      title: res.data.message,
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                });
            } catch (error) {}
          } else if (res.data.status === "false") {
            if (res.data.id_message === "403") {
              Swal.fire({
                title: "Atención! ",
                titleColor: "#E85217",
                text: `${res.data.message}`,
                showCancelButton: true,
                confirmButtonColor: "#E85217",
                cancelButtonColor: "#666666",
                confirmButtonText: "Si!",
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.href = "./Registro";
                }
              });
            } else {
              Swal.fire({
                title: "Atención! ",
                titleColor: "#E85217",
                text: `${res.data.message}`,
                confirmButtonColor: "#E85217",
              });
            }
          }
        });
    } catch (err) {
      if (!err?.res) {
      }
    }
  };

  const handleSubmitRes = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(
          RECUP_CONTRASENA,
          JSON.stringify({
            ...values,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          if (res.data.status === "true") {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              position: "center",
              icon: "success",
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              window.location.href = "./";
            }, 1650);
          }
          if (res.data.status === "false") {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              position: "center",
              icon: "error",
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    } catch (error) {}
  };

  const handleChange = async (e) => {
    await setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleInicio = (e) => {
    e.preventDefault();
    window.location.href = "/";
  };
  
  useEffect(() => {
    let interval = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false); // Detener el contador cuando llega a cero
    }
    if (seconds === 0) {
      setReloadBtn(true);
      setIsSubmitting(false);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  const recargarWeb = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  const inputs = [
    {
      id: 1,
      name: "dniCli",
      type: "number",
      placeholder: " ",
      errorMessage: "Campo Obligatorio",
      pattern: "/^{8}(?:[-]{4})?$/",
      label: "Dni",
      required: true,
    },
    {
      id: 2,
      name: "claveUser",
      type: "text",
      placeholder: " ",
      errorMessage: "Revise el Codigo enviado en su email",
      label: "Código",
      required: true,
    },
    {
      id: 3,
      name: "passVieja",
      type: "password",
      placeholder: " ",
      label: "Contraseña Actual",
      required: true,
    },
    {
      id: 4,
      name: "passNueva",
      type: "password",
      placeholder: " ",
      errorMessage:
        "La contraseña debe tener entre 8 y 20 caracteres! (campo obligatorio)",
      label: "Nueva Contraseña",
      pattern: `^[A-Za-z0-9]{4,20}`,
      required: true,
    },
    {
      id: 5,
      name: "password2",
      type: "password",
      placeholder: " ",
      errorMessage: "Las contraseñas no coinciden.",
      label: "Confirmar Nueva Contraseña",
      pattern: values.passNueva ? `^(?:${values.passNueva})$` : ".*",
      required: true,
    },
  ];

  return (
    <main style={backgroundImageMain}>
      <div className="container-fluid conteRc">
        <div className="row contBoxRc">
          <div className="col-xl-4 col-lg-5 col-md-8 col-sm-10 col-11 boxRcDos">
            <div className="row ">
              <div className="col-12 contenedorImgLogin">
                <img
                  src={ImgLogo}
                  className="img-logoRc img-fluid"
                  alt="logo acopio"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <form
                  className="form"
                  onSubmit={
                    showInputs === true ? handleSubmitRes : handleSubmitCam
                  }
                >
                  <div className="contTitleRc">
                    <span className="spanTituloRc">
                      {showInputs === true
                        ? "Recuperar Contraseña"
                        : "Cambiar Contraseña"}
                    </span>
                  </div>
                  <div className="form__containerDos">
                    {inputs.map((input) => {
                      if (!showInputs && (input.id === 1 || input.id === 2)) {
                        // Inputs ocultos con type="hidden" y valor desde el Local Storage
                        return (
                            <input
                              key={input.id}
                              type="hidden"
                              name={input.name}
                              value={values[input.name]}
                              onChange={handleChange}
                            />
                            
                        );
                      }
                      if (showInputs && input.id === 3) {
                        return <input key={input.id} type="hidden" />;
                      } else {
                        // Renderizar todos los demás inputs
                        return (
                          <FormInput
                            key={input.id}
                            {...input}
                            value={input.id !== 3 ? values[input.name] : password}
                            onChange={input.id !== 3 ? handleChange : (e) => setPassword(e.target.value)}
                          />
                        );
                      }
                    })}
                    {isSubmitting && (
                      <div
                        id="divMensajeAguarde"
                        style={{
                          marginTop: "5px",
                          paddingBottom: "10px",
                          display: "grid",
                          placeItems: "center",
                        }}
                        className="hidden"
                      >
                        <span
                          style={{
                            color: "#fff",
                            margin: "0 0 10px 0",
                            fontSize: "15px",
                          }}
                        >
                          {showInputs === true ? "Reseteo":"Cambio"} en proceso {seconds}
                        </span>
                        <div className="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    )}
                    {reloadBtn && (
                      <div className="row contenedorBtnsIni">
                        <div className="col-12" style={{ color: "#fff" }}>
                          {" "}
                          <span>Parece que algo salio mal</span>
                          <br></br>
                          <span
                            style={{ fontSize: "11.5px" }}
                          >{textError}</span>
                        </div>
                        <div className="col-6">
                          {" "}
                          <a className="botonRegis" href="./">
                            <FontAwesomeIcon
                              icon={faArrowLeft}
                              className="iconoMov"
                              style={{ top: "3px" }}
                            />
                            Volver a Inicio
                          </a>
                        </div>
                        <div
                          className="col-6 contBotInicio"
                          style={{ marginTop: "10px" }}
                        >
                          <button
                            className="btn btnLogin"
                            onClick={recargarWeb}
                          >
                            Reintentar
                          </button>
                        </div>
                      </div>
                    )}
                    {ocultarBrn && (
                      <div className="row contenedorBtnsIni">
                        {!showInputs && (
                        <div className="col-6">
                          <a
                            className="colorBlan"
                            href="/"
                            onClick={handleInicio}
                          >
                            <FontAwesomeIcon
                              icon={faArrowLeft}
                              className="iconoMov"
                            />
                            <span className="txtMov">Volver al Inicio</span>
                          </a>
                        </div>
                      )}
                      <div className={showInputs === true ? "col-6 contBotRc" : "col-6"} style={{height:"45px"}}>
                        <button className="btn btnRcDos">
                          Cambiar Contraseña
                        </button>
                      </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ResetearContraseña;

