import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Grafico from '../pages/Grafico';
import Movimientos from '../pages/Movimientos';
import Inicio from '../pages/Inicio';
import Login from '../pages/Login';
import Registro from '../pages/Registro';
import RecuperarContrasena from '../pages/RecuperarContrasena';
import { getAuth, signInAnonymously } from "firebase/auth";
import ResetearContrasena from '../pages/ResetearContrasena';
import InstructivoIntalacion from '../pages/InstructivoIntalacion';
import Historial from '../pages/Historial';


function App() {
  const loguearse = () => {
    signInAnonymously(getAuth()).then(usuario => {});
  }

  React.useEffect(() => {
    loguearse();
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Inicio />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/registro" element={<Registro />}></Route>
        <Route exact path="/movimientos" element={<Movimientos />}></Route>
        <Route exact path="/estadisticas" element={<Grafico />}></Route>
        <Route exact path="/historialcotiz" element={<Historial />}></Route>
        <Route exact path="/instructivoinstalacion" element={<InstructivoIntalacion />}></Route>
        <Route exact path="/recuperarcontrasena" element={<RecuperarContrasena />}></Route>
        <Route exact path="/resetearcontrasena" element={<ResetearContrasena />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
