import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Img from '../assets/ZUCOM-01.jpg'
import ImgDo from '../assets/ZUCOM-02.jpg'
import ImgTre from '../assets/ZUCOM-03.jpg'
import "../css/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";



const CarrucelExp = ()=>{
  const handleOnDragStart = e => e.preventDefault();

  const items = [
    <div className="item" key="1">
      <img src={Img} alt="imagen 1" className='imgZucomExp' onDragStart={handleOnDragStart} />
      <div className="caption">
      </div>
    </div>,
    <div className="item" key="2">
      <img src={ImgDo} alt="imagen 2" className='imgZucomExp' onDragStart={handleOnDragStart} />
      <div className="caption">
      </div>
    </div>,
    <div className="item" key="3">
      <img src={ImgTre} alt="imagen 3" className='imgZucomExp' onDragStart={handleOnDragStart} />
      <div className="caption">
      </div>
    </div>
  ];

  const responsive = {
    0: { items: 1 },
    1024: { items: 3 }
  };

  return (
    <AliceCarousel
    mouseTracking
    items={items}
    responsive={responsive}
    autoPlay
    autoPlayInterval={30000000}
    showSlideInfo={false}
    slideToIndex={0}
    dotsDisabled={false}
    buttonsDisabled={false}
    infinite={true}
    renderPrevButton={({ isDisabled }) => (
      <button className='buttonPrev' disabled={isDisabled}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    )}
    renderNextButton={({ isDisabled }) => (
      <button className='buttonNext' disabled={isDisabled}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    )}
  />
  );
}

export default CarrucelExp