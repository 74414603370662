import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging"

const firebaseConfig = {

  apiKey: "AIzaSyBv_8lmBf-KrZ9hfzoMNnCkmX67JZtfnms",

  authDomain: "notificaciones-inversion-52592.firebaseapp.com",

  projectId: "notificaciones-inversion-52592",

  storageBucket: "notificaciones-inversion-52592.appspot.com",

  messagingSenderId: "735067207206",

  appId: "1:735067207206:web:6159d8fcffe223c6b398e7",

  measurementId: "G-03EPJ7Y8M8"

};


const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('../public/firebase-messaging-sw.js')
        .then(registration => {
            messaging.useServiceWorker(registration);
        })
        .catch(error => {
            console.error('Error registering service worker: ', error);
        });
}