import { useEffect } from "react"
import axios from "axios";



const URL_API_CEREALES = "https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerSeriesCerealesRosario"

const ApiCerealesRosario = () => {
    
    
    const apiCereales = async (e) => {
        try {
            await axios.get(URL_API_CEREALES)
                .then(res => res.data.message)
                .then(res => mostrarDattos(res))
        } catch (error) {

        }
    }
    useEffect(() => {
        apiCereales();
    },[]);
    const mostrarDattos = (data) => {
        let valorSoja = data.Soja
        let valorTrigo = data.Trigo
        let valorMaiz = data.Maiz
        document.getElementById('valSoja').innerHTML = (`${valorSoja}`);
        document.getElementById('valTrigo').innerHTML = (`${valorTrigo}`);
        document.getElementById('valMaiz').innerHTML = (`${valorMaiz}`);
    }
}

export default ApiCerealesRosario