import React, { useState, useEffect } from "react";

function Countdown() {
    const [time, setTime] = useState(60);

    useEffect(() => {
        if (time <= 0) return;

        const interval = setInterval(() => {
            setTime(time => time - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [time]);

    return (

        <span className='txtTimer' id='countdown'>¿No has recibido el codigo? Solicita un nuevo codigo en {time} segundos</span>

    );
}

export default Countdown;
