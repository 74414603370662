import React, { useState, useEffect } from 'react';

const CarrucelCentroDist = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    const timer = setInterval(nextImage, 5000); // Cambia de imagen cada 2 segundos

    return () => {
      clearInterval(timer); // Limpia el temporizador al desmontar el componente
    };
  }, []);

  return (
   
        <div className="carousel">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Image ${index}`}
          className={index === currentIndex ? 'active' : 'hidden'}
        />
      ))}
    </div>
  );
};

export default CarrucelCentroDist;
