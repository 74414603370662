import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Img from "../assets/Dark_background_1920x1080.png";
import ImgLogo from "../assets/logo-zuppa-hermanos-acopio.png";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import "../css/style.css";
import axios from "axios";
import FormInput from "../components/FormInput";
import { Alert } from "bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const urlApiJs = "https://loteosanseverino.com.ar/admin/api";

const backgroundImageMain = {
  backgroundImage: `url(${Img})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
};

const Registro = () => {
  const urlApiUsu = "https://www.zuppahermanos.com.ar/virtual/api/auth.php";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ocultarBrn, setOcultarBtn] = useState(true);
  const [reloadBtn, setReloadBtn] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [textError, setTextError] = useState("Porfavor revise sus conexión y vuelva a intetarlo mas tarde")
  const [selectedOptionLoc, setSelectedOptionLoc] = useState("");
  const [values, setValues] = useState({
    accion: "registrar_cliente",
    dniClie: "", // validar
    codClieSinc: "", // validar
    nombre: "", // validar
    apellido: "", // validar
    email: "", // validar
    telefono: "", // validar
    password: "", // validar
    direccion: "",
    localidad: "",
    desc_localidad: "",
    cod_prov: "",
    desc_provincia: "",
  });
  const [seconds, setSeconds] = useState(30);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval = null;
    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsActive(false); // Detener el contador cuando llega a cero
    }
    if (seconds === 0) {
      setReloadBtn(true);
      setIsSubmitting(false);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
    });
  }, []);
  function selecProv() {
    Swal.fire({
      width: "20em",
      color: "#fff",
      background: "#2B2B2B",
      icon: "error",
      text: "Debes Seleccionar una provincia",
    });
  }
  
  const recargarWeb = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Establecer el estado de isSubmitting a true antes de enviar el formulario
    setOcultarBtn(false); // Establecer el estado de ocultarBtn en false antes de enviar el formulario
    setIsActive((prevIsActive) => !prevIsActive);
    try {
      await axios
        .post(
          urlApiUsu,
          JSON.stringify({
            ...values,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((response) => {
          if (response.data.status === "true") {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              position: "center",
              icon: "success",
              text: "Registrado con éxito. Revise su correo electrónico para activar la cuenta",
              confirmButtonColor: "#E85217",
            }).then((result) => {
              setSeconds(0);
              if (result.isConfirmed) {
                window.location.href = "/";
              }
            });
          } else {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              title: "Atención! ",
              titleColor: "#E85217",
              text: `${response.data.message}`,
              confirmButtonColor: "#E85217",
            }).then((result) => {
              if (result.isConfirmed) {
                setSeconds(0);
                setTextError(response.data.message);
              }
            });
            setTextError(response.data.message);
          }
        });
    } catch (err) {
      if (!err?.response) {
      }
    }
  };

  const handleChange = async (e) => {
    await setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    //obtenerTxtLocal();
    const selectedText = e.target.options[e.target.selectedIndex].text;
    setSelectedOptionLoc(selectedText);
  };

  const handleChangee = async (e) => {
    await setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    //obtenerTxtProv();
    const selectedText = e.target.options[e.target.selectedIndex].text;
    setSelectedOption(selectedText);
    let codigoProv = document.getElementById("cod_prov").value;
    if (codigoProv === "") selecProv();
    else {
      axios({
        url: urlApiJs + "/general.php",
        method: "post",
        headers: {
          "Content-type": "text/html; charset=UTF-8",
        },
        data: {
          accion: "obtenerLocalidadesProvincia",
          provincia: codigoProv,
          top: 10,
          unido: 1,
        },
      })
        .then((res) => {
          let arrLoca = res.data.message;
          let options = '<option value=""> Seleccionar..</option>';

          if (arrLoca.length > 0) {
            for (let fl = 0; fl < arrLoca.length; fl++) {
              options =
                options +
                '<option value="' +
                arrLoca[fl]["codigo"] +
                '"> ' +
                arrLoca[fl]["nombre"] +
                "</option>";
            }
            options = options + '<option value="">--</option>';
          }
          document.getElementById("localidad").innerHTML = options;
        })
        .catch((err) => {
        });
    }
  };

  useEffect(() => {
    document.getElementById("desc_provincia").value = selectedOption;
    document.getElementById("desc_provincia").value = selectedOptionLoc;
  }, [selectedOption, selectedOptionLoc]);

  const inputs = [
    {
      id: 1,
      name: "codClieSinc",
      type: "number",
      placeholder: " ",
      errorMessage: "Revise el ticket de compra (campo obligatorio)",
      label: "Código Cliente",
      pattern: "[0-9]{4,8}$",
      required: true,
    },
    {
      id: 2,
      name: "nombre",
      type: "text",
      placeholder: " ",
      label: "Nombre",
      errorMessage: "Campo Obligatorio",
      pattern: `^[A-Za-z0-9 ]{2,16}$`,
      required: true,
    },
    {
      id: 3,
      name: "apellido",
      type: "text",
      placeholder: " ",
      label: "Apellido",
      errorMessage: "Campo Obligatorio",
      pattern: `^[A-Za-z0-9 ]{2,16}$`,
      required: true,
    },
    {
      id: 4,
      name: "dniClie",
      type: "number",
      placeholder: " ",
      errorMessage: "Campo Obligatorio",
      pattern: "[0-9]{4,}",
      label: "Dni",
      required: true,
    },
    {
      id: 5,
      name: "email",
      type: "email",
      placeholder: " ",
      errorMessage:
        "Por favor, ingrese una dirección de correo electrónico válida! (campo obligatorio)",
      label: "Email",
      required: true,
    },
    {
      id: 6,
      name: "telefono",
      type: "number",
      placeholder: " ",
      errorMessage:
        "Por favor, ingrese un número de teléfono (campo obligatorio)",
      label: "Numero de Teléfono",
      required: true,
    },
    {
      id: 7,
      name: "password",
      type: "password",
      placeholder: " ",
      errorMessage:
        "La contraseña debe tener entre 8 y 20 caracteres!(campo obligatorio)",
      label: "Contraseña",
      pattern: `^[A-Za-z0-9]{4,20}`,
      required: true,
    },
    {
      id: 8,
      name: "password2",
      type: "password",
      placeholder: " ",
      errorMessage: "Las contraseñas no coinciden!",
      label: "Confirmar Contraseña",
      pattern: values.password,
      required: true,
    },
    {
      id: 9,
      name: "direccion",
      type: "text",
      placeholder: " ",
      errorMessage: "",
      label: "Dirección",
    },
  ];
  return (
    <body>
      <main style={backgroundImageMain}>
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-7 col-sm-8 col-11 boxRegistro">
            <div className="row ">
              <div className="col-12 contenedorImgLogin">
                <img
                  src={ImgLogo}
                  className="img-logoRegistro img-fluid"
                  alt="logo acopio"
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <form className="form" onSubmit={handleSubmit}>
                    <h2 className="form__title--registro">Regístrate</h2>
                    {}
                    {inputs.map((input) => (
                      <FormInput
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={handleChange}
                      />
                    ))}
                    <div className="form__group">
                      <div>
                        <select
                          required
                          name="cod_prov"
                          id="cod_prov"
                          className="form__input selectProvincias"
                          aria-describedby="sizing-addon3"
                          value={values.cod_prov}
                          onChange={handleChangee}
                        >
                          <option value="">Provincia</option>
                          <option value="06">Buenos Aires</option>
                          <option value="10">Catamarca</option>
                          <option value="22">Chaco</option>
                          <option value="26">Chubut</option>
                          <option value="02">
                            Ciudad Autónoma de Buenos Aires
                          </option>
                          <option value="14">Córdoba</option>
                          <option value="18">Corrientes</option>
                          <option value="30">Entre Ríos</option>
                          <option value="34">Formosa</option>
                          <option value="38">Jujuy</option>
                          <option value="42">La Pampa</option>
                          <option value="46">La Rioja</option>
                          <option value="50">Mendoza</option>
                          <option value="54">Misiones</option>
                          <option value="58">Neuquén</option>
                          <option value="62">Río Negro</option>
                          <option value="66">Salta</option>
                          <option value="70">San Juan</option>
                          <option value="74">San Luis</option>
                          <option value="78">Santa Cruz</option>
                          <option value="82">Santa Fe</option>
                          <option value="86">Santiago del Estero</option>
                          <option value="94">
                            Tierra del Fuego, Antártida e Islas del Atlántico
                            Sur
                          </option>
                          <option value="90">Tucumán</option>
                        </select>
                      </div>
                    </div>
                    <div className="form__group">
                      <div>
                        <input
                          name="desc_provincia"
                          id="desc_provincia"
                          type="hidden"
                          value={values.desc_provincia}
                          onChange={handleChangee}
                        />
                      </div>
                    </div>
                    <div className="form__group">
                      <div>
                        <select
                          required
                          name="localidad"
                          id="localidad"
                          className="form__input selectProvincias"
                          aria-describedby="sizing-addon3"
                          value={values.localidad}
                          onChange={handleChange}
                        >
                          <option value="">Localidad</option>
                        </select>
                      </div>
                    </div>
                    <div className="form__group">
                      <div>
                        <input
                          name="desc_localidad"
                          id="desc_localidad"
                          type="hidden"
                          value={values.desc_localidad}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {isSubmitting && (
                      <div
                        id="divMensajeAguarde"
                        style={{
                          marginTop: "5px",
                          paddingBottom: "10px",
                          display: "grid",
                          placeItems: "center",
                        }}
                        className="hidden"
                      >
                        <span
                          style={{
                            color: "#fff",
                            margin: "0 0 10px 0",
                            fontSize: "15px",
                          }}
                        >
                          Registro en proceso {seconds}
                        </span>
                        <div className="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    )}

                    {reloadBtn && (
                      <div className="row contenedorBtnsIni">
                        <div className="col-12" style={{ color: "#fff" }}>
                          {" "}
                          <span>Parece que algo salio mal</span>
                          <br></br>
                          <span
                            style={{ fontSize: "11.5px" }}
                          >{textError}</span>
                        </div>
                        <div className="col-6">
                          {" "}
                          <a className="botonRegis" href="./">
                            <FontAwesomeIcon
                              icon={faArrowLeft}
                              className="iconoMov"
                              style={{ top: "3px" }}
                            />
                            Volver a Inicio
                          </a>
                        </div>
                        <div
                          className="col-6 contBotInicio"
                          style={{ marginTop: "10px" }}
                        >
                          <button
                            className="btn btnLogin"
                            onClick={recargarWeb}
                          >
                            Reintentar
                          </button>
                        </div>
                      </div>
                    )}
                    {ocultarBrn && (
                      <div className="row contenedorBtnsIni">
                        <div className="col-6">
                          {" "}
                          <a className="botonRegis" href="./">
                            Ya tengo cuenta
                          </a>
                        </div>
                        <div className="col-6 contBotInicio">
                          <button className="btn btnLogin">Registrar</button>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </body>
  );
};

export default Registro;
