import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import Img from "../assets/logo-zuppa-hermanos-acopio.png";
import ImgLogoCarga from "../assets/logo_con_trazo.png";
import IconMaiz from "../assets/icono-maiz.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faArrowRightFromBracket,
  faCircleDown,
  faMoneyBillWave,
  faFileInvoiceDollar,
  faWheatAwn,
  faChartSimple,
  faCircleInfo,
  faBars,
  faXmark,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGem,
  faEye,
  faBell,
  faPenToSquare,
} from "@fortawesome/free-regular-svg-icons";
import { faEnvira } from "@fortawesome/free-brands-svg-icons";
import ImgFondo from "../assets/Dark_background_1920x1080.png";
import ValDolar from "../Apis/apiDolar";
import ValDolarBlue from "../Apis/apiDolarBlue";
import Swal from "sweetalert2";
import "../css/style.css";
import "../css/load.css";
import axios from "axios";
import "styled-components";
import ApiCerealesRosario from "../Apis/apiCerealesRosario";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiCotizZucom from "../Apis/apiCotizZucom";
import CarrucelExp from "../components/CarrucelExp";
import CarrucelCentroDist from "../components/CarrucelCentroDist";
import ArtiBotComponent from "../components/chatbot/ArtiBotComponet";

const backgroundImageMain = {
  backgroundImage: `url(${ImgFondo})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100%",
};
const DATOS_ACOPIO = "https://www.zuppahermanos.com.ar/virtual/api/acopio.php";

const REFRESH_TOKEN =
  "https://www.zuppahermanos.com.ar/virtual/api/refreshTokenCliente.php";

const LOGIN_URL = "https://www.zuppahermanos.com.ar/virtual/api/auth.php";

const Inicio = () => {
  const [isReadyForCarga, setIsReadyForCarga] = useState(true);
  const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);
  const [isReadyForNotification, setIsReadyForNotification] = useState(false);
  const [iniSinDatos, setIniSinDatos] = useState(false);
  const [iniConDatos, setIniConDatos] = useState(true);
  const [botTrue, setBotTrue] = useState(false);
  const [valPassword, setValPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [nomUser, setNomUser] = useState("");
  const [tokenVal, setTokenVal] = useState("");
  const [expUnica, setExpUnica] = useState(false);
  const [tokenValido, setTokenValido] = useState(false);
  const [data, setData] = useState({
    acopio: "",
    ape_cliente: "",
    cant_acopio: "",
    cod_acopio: "",
    cod_clie: "",
    cotiz_acopio_actual: "",
    denominacion: "",
    nom_ape: "",
    nom_cliente: "",
    cotiz_acopio_actual_entero: "",
    cotiz_acopio_actual_decimal: "",
    tot_acopiado_inicio: "",
    variacion: "",
  });
  const [datosUs, setDatosUs] = useState(true);

  /* Funcion para desloguear al usuario */

  const Logout = () => {
    localStorage.removeItem("userDataAcopio");
    localStorage.removeItem("token");
    localStorage.removeItem("nombre");
    localStorage.removeItem("apellido");
    window.location.href = "/";
  };

  /* Funcion para verificar si el token esta vencido, si esta vencido lo renueva, pasada las 12 se desloguea*/

  const refreshToken = async (e) => {
    let tokeen = localStorage.getItem("token");
    try {
      const res = await axios.post(
        REFRESH_TOKEN,
        JSON.stringify({
          token: tokeen,
        }),
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (res.data.status === "true") {
        let tooken = res.data.token;
        localStorage.setItem("token", tooken);
        setTokenValido(true);
        setIniSinDatos(false);
        setIniConDatos(true);
        setTokenVal(tooken);
        datosUsuarios(tooken);
      } else if (res.data.status === "false") {
        localStorage.setItem("token", "");
        setIniSinDatos(true);
        setDatosUs(false);
        setIniConDatos(false);
        setTokenValido(false);
        setTokenVal("");
      }
    } catch (error) {
      // Manejar errores aquí
    }
  };

  /* Funcion para cargar los datos de inversion del usuario*/
  useEffect(() => {
    refreshToken();
  }, []);

  const datosUsuarios = async (tokn) => {
    let codZh = localStorage.getItem("cod_zh");

    try {
      await axios
        .post(
          DATOS_ACOPIO,
          JSON.stringify({
            accion: "obtenerAcopiosDelCliente",
            cod_cli_zh: codZh,
            token: tokn,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0) {
              const acopio = res.data[0].denominacion;
              //document.getElementById("nomCoin").innerHTML = acopio;

              const total_acop_actu = parseFloat(
                res.data[0].tot_acopiado_actual
              ).toFixed(2);
              const total_acop_actu_array = total_acop_actu.split(".");
              const total_acop_actu_entero = new Intl.NumberFormat(
                "es-AR"
              ).format(total_acop_actu_array[0]);
              const total_acop_actu_decimal = total_acop_actu_array[1];
              document.getElementById("signoPeso").innerHTML = "$";

              const cotiz_actual = res.data[0].cotiz_acopio_actual;
              const cotiz_actual_final = new Intl.NumberFormat("es-AR", {
                style: "currency",
                currency: "ARS",
              }).format(cotiz_actual);

              const cant_acopio = res.data[0].cant_acopio;
              const cant_acop_final = Number(cant_acopio).toFixed(2);
              setData((prevValues) => ({
                ...prevValues,
                acopio: res.data[0].acopio,
                ape_cliente: res.data[0].ape_cliente,
                cant_acopio: cant_acop_final,
                cod_acopio: res.data[0].cod_acopio,
                cod_clie: res.data[0].cod_clie,
                cotiz_acopio_actual: cotiz_actual_final,
                denominacion: acopio,
                nom_ape: res.data[0].nom_ape,
                nom_cliente: res.data[0].nom_cliente,
                cotiz_acopio_actual_entero: total_acop_actu_entero,
                cotiz_acopio_actual_decimal: total_acop_actu_decimal,
                tot_acopiado_inicio: res.data[0].tot_acopiado_inicio,
                variacion: res.data[0].variacion,
              }));
              setIniConDatos(true);
              setIniSinDatos(false);
            } else if (res.data.length === 0) {
              setData([]);
              setDatosUs(true);
              setIniConDatos(false);
              setIniSinDatos(false);
            } else if (res.data.status === "false" ||res.data.id_message === "905" || res.data.id_message === "900" ) {
              refreshToken();
            }
          } else if (
            res.data.status === "false"
          ) {
            setIniConDatos(false);
            setIniSinDatos(true);
            refreshToken();
          }
        });
    } catch (err) {}
  };

  /* Funcion para activar el token para notificaciones de firebase*/
  const activarMensajes = async () => {
    const token = await getToken(messaging, {
      vapidKey:
        "BMWw5bjl1wRMnNJaum67X33pUrUGwp3Dn89G5h8Y2ZlCQPWg4ayO4z-I3TX-sSXKWB9zNuWOQV20cy7bP6wPydk",
    }).catch((error) => error
    );

    if (token) localStorage.setItem("tokenNoti", token);
    if (!token) ;
  };

  /* Funcion de saludo dependiendo la hora del dia */
  const saludoCliente = () => {
    let d = new Date();
    let hora_actual = "";
    let saludoInicial = "";

    hora_actual = d.getHours();
    saludoInicial = "BUENAS NOCHES";
    if (hora_actual > 6 && hora_actual <= 12) saludoInicial = "BUEN DÍA";
    else if (hora_actual > 12 && hora_actual <= 20)
      saludoInicial = "BUENAS TARDES ";
    if (localStorage.getItem("nombre") !== null) {
      let nom = localStorage.getItem("nombre");
      document.getElementById(
        "nomCliente"
      ).innerHTML = `${saludoInicial} ${nom}`;
      setNomUser(nom);
    }
    if (localStorage.getItem("nombre") === null) {
      document.getElementById("nomCliente").innerHTML = `${saludoInicial}`;
    }
  };

  /* Funcion para mandar el token de firebase a la base de datos*/

  const mandarToken = async (e) => {
    let tokenCli = localStorage.getItem("token");
    let tokenNot = localStorage.getItem("tokenNoti");
    let codClie = localStorage.getItem("cod_zh");
    try {
      await axios
        .post(
          DATOS_ACOPIO,
          JSON.stringify({
            accion: "actualizaTokenFireBase",
            token: tokenCli,
            cod_cli: codClie,
            token_firebase_notif: tokenNot,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
             if (res.data.status === "false" ||res.data.id_message === "905" || res.data.id_message === "900" ) {
              refreshToken();
            }
          }
        });
    } catch (err) {}
  };

  /* Funciones para verificar si hay un token de notificacion existente dentro del localstorage y de la base de datos */

  const verificacionTokenNoti = () => {
    let tokensFire = JSON.parse(localStorage.getItem("tokenFirebase"));
    let tokenNoti = localStorage.getItem("tokenNoti");
    let existeEnBD = false;

    for (var i in tokensFire) {
      let con = tokensFire[i];
      if (con.token_firebase_notif === tokenNoti) {
        existeEnBD = true;
      }
      if (existeEnBD) {
        break;
      }
    }

    if (existeEnBD === true) {
      setIsReadyForNotification(false);
    } else if (existeEnBD === false) {
      setIsReadyForNotification(true);
    }
  };

  /* Funcion para preguntar todos los dias si el usuario desea activar las notificaciones, este mensaje
se deja de preguntar cuando el usuario activa las notificaciones, las bloquea o su dispositivo no las admite
*/

  const mensajeDiario = () => {
    let fechaActual = new Date();
    let _diaActual = fechaActual.toLocaleDateString();
    if (!("Notification" in window)) {
    } else if ("Notification" in window) {
      if (localStorage.getItem("tokenNoti") === null) {
        if (localStorage.getItem("fechaNoti") === null) {
          setTimeout(() => {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              title: "¿Activar Notificaciones?",
              text: "Te notificaremos movimientos en tu cuenta y cambios de la aplicacion.",
              showDenyButton: true,
              denyButtonColor: "#666666",
              confirmButtonText: "Activar",
              confirmButtonColor: "#E85217",
              denyButtonText: `Cancelar`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Comprobamos si los permisos han sido concedidos anteriormente
                if (Notification.permission === "granted") {
                  // Si es correcto, lanzamos una notificación
                }

                // Si no, pedimos permiso para la notificación
                else if (Notification.permission !== "denied") {
                  Notification.requestPermission().then(function (permission) {
                    // Si el usuario nos lo concede, creamos la notificación
                    if (permission === "granted") {
                      // Si es correcto, lanzamos una notificación
                      activarMensajes();
                      Swal.fire({
                        width: "20em",
                        color: "#fff",
                        background: "#2B2B2B",
                        position: "center",
                        icon: "success",
                        title: "Notificaciones Activadas",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  });
                }

                // Por último, si el usuario ha denegado el permiso,
                // y quiere ser respetuoso, no hay necesidad de molestarlo.
              } else {
                let fecha = new Date();
                let _dia = fecha.toLocaleDateString();
                localStorage.setItem("fechaNoti", _dia);
              }
            });
          }, 2700);
        } else if (localStorage.getItem("fechaNoti") < _diaActual) {
          setTimeout(() => {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              title: "¿Activar Notificaciones?",
              text: "Te notificaremos movimientos en tu cuenta y cambios de la aplicacion.",
              showDenyButton: true,
              denyButtonColor: "#666666",
              confirmButtonText: "Activar",
              confirmButtonColor: "#E85217",
              denyButtonText: `Cancelar`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Comprobamos si los permisos han sido concedidos anteriormente
                if (Notification.permission === "granted") {
                  // Si es correcto, lanzamos una notificación
                }

                // Si no, pedimos permiso para la notificación
                else if (Notification.permission !== "denied") {
                  Notification.requestPermission().then(function (permission) {
                    // Si el usuario nos lo concede, creamos la notificación
                    if (permission === "granted") {
                      // Si es correcto, lanzamos una notificación
                      activarMensajes();
                      Swal.fire({
                        width: "20em",
                        color: "#fff",
                        background: "#2B2B2B",
                        position: "center",
                        icon: "success",
                        title: "Notificaciones Activadas",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  });
                }

                // Por último, si el usuario ha denegado el permiso,
                // y quiere ser respetuoso, no hay necesidad de molestarlo.
              } else {
                let fecha = new Date();
                let _dia = fecha.toLocaleDateString();
                localStorage.setItem("fechaNoti", _dia);
              }
            });
          }, 2700);
        }
      } else if (localStorage.getItem("tokenNoti") !== null) {
      }
    }
  };

  /* funcion para preguntar si se permiten las notificaciones en la web */

  const notifyMe = () => {
    Swal.fire({
      width: "20em",
      color: "#fff",
      background: "#2B2B2B",
      title: "¿Activar Notificaciones?",
      text: "Te notificaremos movimientos en tu cuenta y cambios de la aplicacion.",
      showDenyButton: true,
      denyButtonColor: "#666666",
      confirmButtonText: "Activar",
      confirmButtonColor: "#E85217",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Comprobamos si los permisos han sido concedidos anteriormente
        if (Notification.permission === "granted") {
          // Si es correcto, lanzamos una notificación
        }

        // Si no, pedimos permiso para la notificación
        else if (Notification.permission !== "denied") {
          Notification.requestPermission().then(function (permission) {
            // Si el usuario nos lo concede, creamos la notificación
            if (permission === "granted") {
              // Si es correcto, lanzamos una notificación
              activarMensajes();
              Swal.fire({
                width: "20em",
                color: "#fff",
                background: "#2B2B2B",
                position: "center",
                icon: "success",
                title: "Notificaciones Activadas",
                showConfirmButton: false,
                timer: 1500,
              });
              setIsReadyForNotification(false);
              mandarToken();
            }
          });
        }

        // Por último, si el usuario ha denegado el permiso,
        // y quiere ser respetuoso, no hay necesidad de molestarlo.
      }
    });
  };

  /* funcion para poder descargar la app */

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);
  async function downloadApp() {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }

  /* boton para ir a la grilla */
  const handleGrilla = (e) => {
    e.preventDefault();
    window.location.href = "/movimientos";
  };
  const handleHistorial = (e) => {
    e.preventDefault();
    window.location.href = "/historialcotiz";
  };
  const handleCambiarPass = (e) => {
    e.preventDefault();
    window.location.href = "/resetearcontrasena";
  };
  /* boton para ir a estadisticas */
  const handleEstadisticas = (e) => {
    e.preventDefault();
    window.location.href = "/estadisticas";
  };

  /* mandar inicio de sesion desde alerta, esto se ejecuta pasada 12 horas del inicio de sesion del usuario */
  const handleSubmit = async (e) => {
    const dnii = localStorage.getItem("userDataAcopio");
    try {
      await axios
        .post(
          LOGIN_URL,
          JSON.stringify({
            accion: "login_cliente",
            dni: dnii,
            password: password,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.status === "true") {
            localStorage.setItem("nombre_sol", res.data.message.nombre);
            localStorage.setItem("token", res.data.token);
            setTokenVal(res.data.token);
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              position: "center",
              icon: "success",
              title: "Inicio Exitoso",
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              setIniConDatos(true);
              setIniSinDatos(false);
              setTokenValido(true);
              setBotTrue(true);
            }, 1595);
          } else if (res.data.status === "false") {
            if (res.data.id_message === "403") {
              Swal.fire({
                title: "Atención! ",
                width: "20em",
                titleColor: "#E85217",
                color: "#fff",
                background: "#2B2B2B",
                text: `${res.data.message}`,
                showCancelButton: true,
                confirmButtonColor: "#E85217",
                cancelButtonColor: "#666666",
                confirmButtonText: "Si!",
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.href = "./Registro";
                }
              });
            } else {
              Swal.fire({
                title: "Atención! ",
                width: "20em",
                titleColor: "#E85217",
                color: "#fff",
                background: "#2B2B2B",
                text: `${res.data.message}`,
                confirmButtonColor: "#E85217",
              });
            }
          }
        });
    } catch (err) {
      if (!err?.res) {
      }
    } finally {
      datosUsuarios(tokenVal);
    }
  };

  /* alerta de inicio de sesion, esta se activa desde el boton mostrar mis datos,
 el cual aparece despues de 12hs de haber iniciado sesion
  */
  const verificarContra = () => {
    if (
      localStorage.getItem("userDataAcopio") === null ||
      localStorage.getItem("claveUser") === null
    ) {
      localStorage.removeItem("userDataAcopio");
      localStorage.removeItem("token");
      localStorage.removeItem("nombre");
      localStorage.removeItem("apellido");
      window.location.href = "/login";
    }
    if (localStorage.getItem("userDataAcopio") !== null) {
      Swal.fire({
        title: "Ingrese su Contraseña para mostrar sus datos",
        input: "password",
        inputValue: password,
        color: "#fff",
        inputValidator: (value) => {
          if (!value) {
            return "Debe ingresar un valor";
          } else if (value.length < 3) {
            return "La contraseña debe tener al menos 3 caracteres";
          }
        },
        inputAttributes: {
          autocapitalize: "off",
          autocorrect: "off",
        },
        width: "20em",
        background: "#2B2B2B",
        showCancelButton: true,
        cancelButtonText: "Cerrar Sesión",
        confirmButtonColor: "#E85217",
        confirmButtonText: "Iniciar Sesión",
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setValPassword(true);
          setPassword(result.value);
        } else if (result.isDismissed) {
          Logout();
          window.location.href = "./login";
        }
      });
    }
  };

  /* recargar la pagina para actualizacion de datos de inicio */
  useEffect(() => {
    if (valPassword) {
      handleSubmit();
      setValPassword(false);
    }
  }, [valPassword]);

  /* llamada a funciones con actualizacion de datos automaticos */
  useEffect(() => {
    datosUsuarios(tokenVal);
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        setIsReadyForNotification(false);
      }
    }
  }, []);
  useEffect(() => {
    mandarToken();
    setTokenVal(localStorage.getItem("token"));
    datosUsuarios(tokenVal);
  }, [tokenValido]);

  const haldleCerrarExp = (e) => {
    e.preventDefault();
    setExpUnica(false);
    localStorage.setItem("expZucom", "vista");
  };

  const handleMostrarExp = (e) => {
    e.preventDefault();
    setExpUnica(true);
  };
  const handleInstructivo = (e) => {
    e.preventDefault();
    window.location.href = "./instructivoinstalacion";
  };

  const comprobarNoti = () => {
    if ("Notification" in window) {
      if (Notification.permission === "default") {
        setIsReadyForNotification(true);
        mensajeDiario();
      }
      if (Notification.permission === "granted") {
        activarMensajes();
        setIsReadyForNotification(false);
        mandarToken();
      }
      if (Notification.permission === "denied") {
        setIsReadyForNotification(false);
      }
    } else if (!("Notification" in window)) {
      setIsReadyForNotification(false);
    }
  };

  /*tiene varias funciones dentro
1.verifica si hay token de notificacion dentro del localstorage 
2.carga los mensajes de las notificaiones en un cartel cuando el usuario se encuentra dentro de la aplicacion
3. verifiaca si el navegador que esta utilizando el usario permite notificaciones push, si las permite muestra el boton de notificaciones, si no, lo oculta
4.llama a funciones de usuario una unicavez cuando se carga la pagina
*/

  useEffect(() => {
    onMessage(messaging, (message) => {
      toast.info(message.data.body, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
    // Comprobamos si el navegador soporta las notificaciones
    comprobarNoti();
    if (localStorage.getItem("tokenNoti") !== null) {
      setIsReadyForNotification(false);
    }

    /*     if (localStorage.getItem("userDataAcopio") === null){
      setIniConDatos(false)
      setIniSinDatos(false);
      setNuevosUsuarios(true);
    }  */

    verificacionTokenNoti();
    saludoCliente();
    setTimeout(() => {
      setIsReadyForCarga(false);
    }, 1370);
    setTimeout(() => {
      if (localStorage.getItem("expZucom") === null) {
        setExpUnica(true);
      } else if (localStorage.getItem("expZuco") !== null) {
        setExpUnica(false);
      }
    }, 1400);
  }, []);

  function importAll(r) {
    return r.keys().map(r);
  }

  const images = importAll(
    require.context(
      "../assets/fotos-centro-distribucion",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  useEffect(() => {
    let dnicl;
    if (iniConDatos === true) {
      dnicl = localStorage.getItem("userDataAcopio");
      localStorage.setItem("dniCliente", dnicl);
      setTokenValido(true);
    } else if (iniSinDatos === true) {
      localStorage.setItem("dniCliente", "");
    }
  }, [iniConDatos, iniSinDatos]);

  useEffect(() => {
    const currentURL = new URL(window.location.href);
    const params = new URLSearchParams(currentURL.search);
    const codigoZh = localStorage.getItem("cod_sinc");
    const nombreZh = localStorage.getItem("nombre_sol");
    const apellidoZh = localStorage.getItem("apellido");

    setTimeout(() => {
      if (iniSinDatos === true && localStorage.getItem("dniCliente") === "") {
        setBotTrue(false);
        params.delete("i");
        currentURL.search = params.toString();
        window.history.replaceState(
          { path: currentURL.href },
          "",
          currentURL.href
        );
      } else if (
        (iniConDatos === true &&
          localStorage.getItem("dniCliente") === "1001") ||
        localStorage.getItem("dniCliente") === "99887766"
      ) {
        setBotTrue(true);
        currentURL.searchParams.set(
          "i",
          codigoZh + "_" + nombreZh + "_" + apellidoZh
        );
        window.history.pushState(
          { path: currentURL.href },
          "",
          currentURL.href
        );
      } else {
        currentURL.searchParams.set(
          "i",
          codigoZh + "_" + nombreZh + "_" + apellidoZh
        );
        window.history.pushState(
          { path: currentURL.href },
          "",
          currentURL.href
        );
        setBotTrue(false); // Si no cumple ninguna de las condiciones anteriores, establece botTrue como falso
      }
    }, 1000);
  }, [iniConDatos, iniSinDatos]);

  return (
    <div className="divContenedor">
      {isReadyForCarga && (
        <div className="contHijo">
          <div className="divContenedorHijo">
            <div className="contenedor-loader">
              <div>
                <img src={ImgLogoCarga} className="loader1" alt="logo carga" />
              </div>
            </div>
            <div className="conTxt">
              <span className="txtLoad">ZH Inversiones</span>
            </div>
          </div>
        </div>
      )}
      {expUnica && (
        <div className="contenedorExp">
          <div className="row contExp">
            <div className="contSalirExp">
              <FontAwesomeIcon icon={faXmark} onClick={haldleCerrarExp} />
            </div>
            <div className="contImgExp">
              <CarrucelExp />
            </div>
            <div className="col-12 contBtnOmitir">
              <button className="buttonOmitirExp" onClick={haldleCerrarExp}>
                OMITIR
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="contWeb">
        <ToastContainer />
        <header className="header-inicio">
          <div className="container-fluid">
            <div
              className="row lineaNaraja"
              style={{ backgroundColor: "#E85217" }}
            >
              <div className="col-12" style={{ padding: 8 }}></div>
            </div>
          </div>
          <div className="row contHeader">
            <div className="col-xl-10 col-lg-10 col-md-8 col-sm-5 col-5 contLogo">
              <img src={Img} className="img-logo" alt="logo de acopio" />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-5 col-6 contDrp">
              {iniConDatos && (
                <Dropdown className="boxDrop">
                  <Dropdown.Toggle id="dropdown-dark-example1" variant="light">
                    <FontAwesomeIcon icon={faBars} className="colorIcono" />
                    <span className="txtDropdown">MENU</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="menuDropdown" variant="dark">
                    <Dropdown.Header
                      style={{ color: "#fff" }}
                      className="contNomUser"
                    >
                      <FontAwesomeIcon icon={faUserTie} className="iconoUser" />
                      <span className="txtNom">{nomUser}</span>
                    </Dropdown.Header>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={handleCambiarPass}
                    >
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        className="iconoMov"
                      />
                      <span className="txtMov">Cambiar Contraseña</span>
                    </Dropdown.Item>
                    {isReadyForInstall && (
                      <Dropdown.Item
                        type="button"
                        style={{ color: "#fff" }}
                        onClick={downloadApp}
                      >
                        <FontAwesomeIcon
                          icon={faCircleDown}
                          className="iconoMov"
                        />
                        <span className="txtMov">Descargar</span>
                      </Dropdown.Item>
                    )}

                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={handleGrilla}
                    >
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        className="iconoMov"
                      />
                      <span className="txtMov">Movimientos</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={handleHistorial}
                    >
                      <FontAwesomeIcon
                        icon={faClockRotateLeft}
                        className="iconoMov"
                      />
                      <span className="txtMov">Historial ZUCOM</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={handleEstadisticas}
                    >
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        className="iconoMov"
                      />
                      <span className="txtMov">Estadísticas</span>
                    </Dropdown.Item>
                    {isReadyForNotification && (
                      <Dropdown.Item
                        type="button"
                        style={{ color: "#fff" }}
                        onClick={notifyMe}
                      >
                        <FontAwesomeIcon icon={faBell} className="iconoNot" />
                        <span className="txtMov">Activar Notificaciones</span>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={handleMostrarExp}
                    >
                      <FontAwesomeIcon icon={faGem} className="iconoMov" />
                      <span className="txtExpZucom">¿Qué es el ZUCOM?</span>
                    </Dropdown.Item>
                    {isReadyForInstall && (
                      <Dropdown.Item type="button" style={{ color: "#fff" }}>
                        <FontAwesomeIcon
                          icon={faFileInvoiceDollar}
                          className="iconoMov"
                        />
                        <span
                          className="txtInstruc"
                          onClick={handleInstructivo}
                        >
                          Instructivo de Instalación
                        </span>
                      </Dropdown.Item>
                    )}

                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={Logout}
                    >
                      <FontAwesomeIcon
                        icon={faArrowRightFromBracket}
                        className="iconoMov"
                      />
                      <span className="txtMov">Cerrar Sesión</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {iniSinDatos && (
                <Dropdown className="boxDrop">
                  <Dropdown.Toggle id="dropdown-dark-example1" variant="light">
                    <FontAwesomeIcon icon={faBars} className="colorIcono" />
                    <span className="txtDropdown">MENU</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="menuDropdown" variant="dark">
                    {isReadyForInstall && (
                      <Dropdown.Item
                        type="button"
                        style={{ color: "#fff" }}
                        onClick={downloadApp}
                      >
                        <FontAwesomeIcon
                          icon={faCircleDown}
                          className="iconoMov"
                        />
                        <span className="txtMov">Descargar</span>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={handleMostrarExp}
                    >
                      <FontAwesomeIcon icon={faGem} className="iconoMov" />
                      <span className="txtExpZucom">¿Qué es el ZUCOM?</span>
                    </Dropdown.Item>
                    <Dropdown.Item type="button" style={{ color: "#fff" }}>
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        className="iconoMov"
                      />
                      <span className="txtInstruc" onClick={handleInstructivo}>
                        Instructivo de Instalación
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        </header>
        <main style={backgroundImageMain}>
          <div className="container-fluid" style={{ paddingTop: "1,5%" }}>
            <div className="contBienvenida">
              <span className="nomCliente" id="nomCliente"></span>
            </div>
            {botTrue === true && iniConDatos === true ? (
              <ArtiBotComponent />
            ) : (
              <div></div>
            )}
            {iniConDatos ? (
              <div className="row justify-content-center continver">
                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 col-12 divTitulo">
                  <div className="row divBienvenida">
                    <div className="col-12 contInv">
                      <span className="invActual">TU INVERSIÓN</span>
                    </div>
                    <div className="row contenedorCoins">
                      <div className="col-12 container-img">
                        <FontAwesomeIcon icon={faGem} className="imgDiamante" />
                        <span className="cantCoins" id="cantAcopio">
                          {data.cant_acopio}
                        </span>
                        <span className="tituloCoin" id="nomCoin">
                          {data.denominacion}
                        </span>
                      </div>
                    </div>
                    <div className="row justify-content-center contTotAcop">
                      <div className="col-xl-7 col-lg-5 col-md-6 col-sm-10 col-12 contTotalZ">
                        <a
                          className="colorBlan"
                          href="/movimientos"
                          onClick={handleGrilla}
                        >
                          <span className="signoPeso" id="signoPeso"></span>
                          <span className="valCoin" id="totalAcopiado">
                            {data.cotiz_acopio_actual_entero}
                          </span>
                          <span className="decimalTotal" id="decimalTotal">
                            {data.cotiz_acopio_actual_decimal}
                          </span>
                          <FontAwesomeIcon icon={faEye} className="iconoOjo" />
                        </a>
                      </div>
                    </div>
                    <div className="row contenedorBotMov">
                      <div className="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-7 containerBtnMov">
                        <a
                          className="colorBlan"
                          href="/movimientos"
                          onClick={handleGrilla}
                        >
                          <FontAwesomeIcon
                            icon={faFileInvoiceDollar}
                            className="iconoMov"
                          />
                          <span className="txtMov">VER MOVIMIENTOS</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              datosUs && (
                <div className="row">
                  <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 col-11 divTitulo contCarga">
                    <div className="row">
                      <div className="col-12 contMov">
                        <div className="spinner"></div>
                      </div>
                      <div className="col-12 contMov">
                        <span>Aguarde un Momento</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
            {iniSinDatos && (
              <div className="row contBotonRelog">
                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-7 col-10 contBotRelog">
                  <button className="btn btnLogin" onClick={verificarContra}>
                    <FontAwesomeIcon icon={faCircleInfo} className="iconoMov" />
                    <span className="txtRelog">MOSTRAR MI INVERSIÓN</span>
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="row justify-content-center contenedorVar">
            <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 col-12">
              <div className="row justify-content-center">
                <div className="col-12 txtCotiz">
                  <span>COTIZACIONES DEL DÍA</span>
                </div>
              </div>
              <div className="row contCotiz">
                <div className="col-5 contTxtZ contZucom">
                  <FontAwesomeIcon icon={faGem} className="iconoDiam" />
                  <span className="txtDolar">ZUCOM </span>
                </div>
                <ApiCotizZucom />
                <div className="col-5 contenedorIcc contZucom">
                  <span className="txtValIcc" id="valZucom">
                    {data.cotiz_acopio_actual}
                  </span>
                </div>
              </div>
              <div className="row contCotiz">
                <div className="col-7 contTxtZ contZucom">
                  <img
                    src={require("../assets/brick-wall.png")}
                    alt="iccMca"
                    className="iconoIccMca"
                  />
                  {/* <img src={require("../assets/construccion-02.png")} alt="iccMca" className="iconoIccMca"/> */}
                  <span className="txtMca">
                    M² CONSTRUCCIÓN
                    <span className="txtParentecis"> (CBA) (1)</span>
                  </span>
                </div>
                <ApiCotizZucom />
                <div className="col-3 contenedorIcc contZucom">
                  <span className="txtValIcc" id="valMca"></span>
                </div>
              </div>
              <div className="row contCotiz">
                <div className="col-5 contTxtZ contZucom">
                  <FontAwesomeIcon
                    icon={faMoneyBillWave}
                    className="iconoDolar"
                  />
                  <span className="txtDolar">DOLAR OFICIAL </span>
                </div>
                <div className="col-5 contenedorIcc contZucom">
                  <ValDolar></ValDolar>
                  <span className="txtValDolar" id="valDolar"></span>
                </div>
              </div>
              <div className="row contCotiz">
                <div className="col-5 contTxtZ contZucom">
                  <FontAwesomeIcon
                    icon={faMoneyBillWave}
                    className="iconoDolarBlue"
                  />
                  <span className="txtDolar">DOLAR BLUE </span>
                </div>
                <div className="col-5 contenedorIcc contZucom">
                  <ValDolarBlue></ValDolarBlue>
                  <span className="txtValDolar" id="valDolarBlu"></span>
                </div>
              </div>
              <ApiCerealesRosario />
              <div className="row contCotiz">
                <div className="col-5 contTxtZ contZucom">
                  <FontAwesomeIcon icon={faEnvira} className="iconoSoja" />
                  <span className="txtDolar">SOJA </span>
                </div>
                <div className="col-5 contenedorIcc contZucom">
                  <span className="txtValIcc" id="valSoja"></span>
                </div>
              </div>
              <div className="row contCotiz">
                <div className="col-5 contTxtZ contZucom">
                  <FontAwesomeIcon icon={faWheatAwn} className="iconoTrigo" />
                  <span className="txtDolar">TRIGO </span>
                </div>
                <div className="col-5 contenedorIcc contZucom">
                  <span className="txtValIcc" id="valTrigo"></span>
                </div>
              </div>
              <div className="row contCotiz">
                <div className="col-5 contTxtZ contZucom">
                  <img src={IconMaiz} className="iconoMaiz" alt="icono maiz" />
                  <span className="txtDolar">MAÍZ </span>
                </div>
                <div className="col-5 contenedorIcc contZucom">
                  <span className="txtValIcc" id="valMaiz"></span>
                </div>
              </div>
              <div className="row cotizPadd">
                <div className="col-12 contTxtBolsa ">
                  <span className="txtBolsa">
                    Precios{" "}
                    <a
                      className="bolsaRos"
                      href="https://www.cac.bcr.com.ar/es/precios-de-pizarra"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Bolsa de Comercio de Rosario
                    </a>
                    , expresados en $/Tn.
                  </span>
                </div>
                <div className="col-12 contTxtEst ">
                  <span className="txtBolsa">*Estimativo Pizarra</span>
                </div>
                <div className="col-12 contTxtEst ">
                  <span className="txtBolsa">
                    (1)Costo de referencia del metro cuadrado de construcción
                    brindado por el{" "}
                    <a
                      className="bolsaRos"
                      href="https://www.colegio-arquitectos.com.ar/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Colegio de Arquitectos
                    </a>{" "}
                    de la Provincia de Córdoba.
                  </span>
                </div>
              </div>
              <div className="row numVersion">
                <div className="col-10 contDist">
                  <span className="txtCarDist">
                    NUESTRO CENTRO DE DISTRIBUCIÓN
                  </span>
                </div>
                <div className="col-2 contVer">
                  <span className="txtVer">v8.2.0</span>
                </div>
              </div>
              <CarrucelCentroDist images={images} />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Inicio;
