import { useState } from "react";
import "../css/login.css";


const FormInput = (props) => {

    const [focused, setFocused] = useState(false)

    const { label, errorMessage, onChange, id, ...inputProps } = props;

    const handleFocus = (e) => {
        setFocused(true);
    };

    return (
        <div className="form__container">
            <div className='form__group'>
                <input className="form__input"
                    id={id}
                    {...inputProps}
                    onChange={onChange}
                    onBlur={handleFocus}
                    onFocus={() =>
                        inputProps.name === "password2" && setFocused(true)
                    }
                    focused={focused.toString()}
                />
                <label htmlFor={id} className='form__label'>{label}</label>
                <span className='form__line'></span>
                <span className="form__errorReg">{errorMessage}</span>
                <div>
                </div>
            </div>
        </div>
    )
}

export default FormInput;