import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import Img from "../assets/logo-zuppa-hermanos-acopio.png";
import ImgLogoCarga from "../assets/logo_con_trazo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faChartSimple,
  faArrowRightFromBracket,
  faCircleDown,
  faAnglesRight,
  faAnglesLeft,
  faArrowLeft,
  faBars,
  faCircleInfo,
  faPenToSquare,
  faXmark,
  faClockRotateLeft,
  faBell,
  faFileInvoiceDollar,
  faGift,
} from "@fortawesome/free-solid-svg-icons";
import { faGem } from "@fortawesome/free-regular-svg-icons";
import ImgFondo from "../assets/Dark_background_1920x1080.png";
import "../css/style.css";
import "../css/load.css";
import axios from "axios";
import "styled-components";
import Swal from "sweetalert2";
import "styled-components";
import CarrucelExp from "../components/CarrucelExp";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase";
import ArtiBotComponent from "../components/chatbot/ArtiBotComponet";

const backgroundImageMain = {
  backgroundImage: `url(${ImgFondo})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  /* backgroundAttachment: 'fixed', */
  width: "100%",
  height: "95vh",
  maxHeight: "100%",
};
const DATOS_ACOPIO = "https://www.zuppahermanos.com.ar/virtual/api/acopio.php";
const REFRESH_TOKEN =
  "https://www.zuppahermanos.com.ar/virtual/api/refreshTokenCliente.php";
const TOKEN_NOTI = "https://www.zuppahermanos.com.ar/virtual/api/acopio.php";

const NUM_ELEMENTOS_POR_PAGINA = 5;
const Movimientos = () => {
  const Logout = () => {
    localStorage.removeItem("userDataAcopio");
    localStorage.removeItem("token");
    localStorage.removeItem("cod_zh");
    localStorage.removeItem("cod_sinc");
    localStorage.removeItem("nombre");
    localStorage.removeItem("apellido");
    window.location.href = "/";
  };

  const refreshToken = async (e) => {
    let tokeen = localStorage.getItem("token");
    try {
      const res = await axios.post(
        REFRESH_TOKEN,
        JSON.stringify({
          token: tokeen,
        }),
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      console.log(res);
      if (res.data.status === "true") {
        let tooken = res.data.token;
        localStorage.setItem("token", tooken);
        datosGrilla();
        datosUsuarios();
      } else if (res.data.status === "false") {
        localStorage.setItem("token", "");
        window.location.href = "./";
      }
    } catch (error) {}
  };

  const [datosUser, setDatosUser] = useState([]);
  const [comprobanteSeleccionado, setComprobanteSeleccionado] = useState(null);
  //const [actualizar, setActualizar] = useState(false);
  const [isReadyForCarga, setIsReadyForCarga] = useState(true);
  const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [nomUser, setNomUser] = useState("");
  const [isReadyForNotification, setIsReadyForNotification] = useState(false);
  const [expUnica, setExpUnica] = useState(false);

  const datosGrilla = async (e) => {
    let codZh = localStorage.getItem("cod_zh");
    let token = localStorage.getItem("token");
    try {
      await axios
        .post(
          DATOS_ACOPIO,
          JSON.stringify({
            accion: "obtenerAcopiosClienteDetalle",
            cod_cli_zh: codZh,
            token: token,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
             if (res.data.length > 0) {
              let resp = res.data;
              setDatosUser(resp);
              const totalPages = Math.ceil(
                resp.length / NUM_ELEMENTOS_POR_PAGINA
              );
              setTotalPages(totalPages);
            }else if (res.data.status === "900" ||res.data.status === "905" || res.data.status === "false") {
              refreshToken();
            }
          }
        });
    } catch (error) {}
  };
  useEffect(() => {
    datosGrilla();
  }, []);

  const filteredData = datosUser.slice(
    (currentPage - 1) * NUM_ELEMENTOS_POR_PAGINA,
    currentPage * NUM_ELEMENTOS_POR_PAGINA
  );

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    const totalPages = Math.ceil(datosUser.length / NUM_ELEMENTOS_POR_PAGINA);
    setTotalPages(totalPages);
    const newStartIndex = (currentPage - 1) * NUM_ELEMENTOS_POR_PAGINA;
    const newEndIndex = Math.min(
      newStartIndex + NUM_ELEMENTOS_POR_PAGINA,
      datosUser.length
    );
    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  }, [datosUser, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const componentDidMount = () => {
    if (!localStorage.getItem("token")) {
      window.location.href = "./";
    }
  };
  const datosUsuarios = async (e) => {
    let codZh = localStorage.getItem("cod_zh");
    let token = localStorage.getItem("token");

    try {
      await axios
        .post(
          DATOS_ACOPIO,
          JSON.stringify({
            accion: "obtenerAcopiosDelCliente",
            cod_cli_zh: codZh,
            token: token,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
             if (res.data.length > 0) {
              let nomMoneda = res.data[0].denominacion;
              document.getElementById("nomCoin").innerHTML = `${nomMoneda}`;

              let cantMoneda = Number(res.data[0].cant_acopio).toFixed(2);
              document.getElementById("cantAcopio").innerHTML = cantMoneda;
            }else if (res.data.status === "false" ||res.data.id_message === "905" || res.data.id_message === "900" ) {
              refreshToken();
            }
          } 
        });
    } catch (err) {}
  };

  const varPagination = () => {
    if (datosUser.length > 5) {
      return (
        <div className="row">
          <div className="col-12 text-center contPaginacion">
            <div>
              <div className="pagination">
                <button
                  className="btn btnSig btnChico btnRed"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <FontAwesomeIcon
                    icon={faAnglesLeft}
                    className="colorIconoBtn"
                  />
                </button>
                <span className="txtPaginacion">
                  Mostrando {startIndex + 1} - {endIndex} de {datosUser.length}{" "}
                </span>
                <button
                  className="btn btnSig btnChico btnRed"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <FontAwesomeIcon
                    icon={faAnglesRight}
                    className="colorIconoBtn"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (datosUser.length < 5) {
      return (
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 contTxtPag">
            <div>
              <p className="txtPaginacion">
                Mostrando {startIndex + 1} - {endIndex} de {datosUser.length}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  function capturarPrimeraLetraPalabras(frase) {
    var palabras = frase.split(" ");
    var letras = palabras.map(function (palabra) {
      return palabra.charAt(0);
    });
    return letras.join("");
  }

  const handleComprobante = (datos) => {
    setComprobanteSeleccionado(datos);
    const tipoCompAcopio = datos.tipo_comp_acopio;
    const observacion = datos.observacion || ""; // Asignar cadena vacía si observación es null
    const suc_comp_acopio = datos.suc_comp_acopio;
    const comp_acopio = datos.comp_acopio;
    const tipoComp = capturarPrimeraLetraPalabras(tipoCompAcopio);

    let htmlContent = `<h4 style="font-size: 17px !important; padding-bottom: 10px !important;">Comprobante: ${tipoComp}-${suc_comp_acopio}-${comp_acopio}</h4>`;
    if (observacion !== "") {
      htmlContent += `<p style="font-size: 17px !important;">Observación: ${observacion}</p>`;
    }

    Swal.fire({
      html: htmlContent,
      width: "22em",
      background: "#2B2B2B",
      confirmButtonColor: "#E85217",
      confirmButtonText: "Ok",
      showLoaderOnConfirm: true,
      color: "#fff",
      customClass: {
        confirmButton: "confirmButton",
        title: "custom_title",
        htmlContainer: "custom_htmlContainer",
      },
    });
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);
  async function downloadApp() {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }
  const saludoCliente = () => {
    let nom = localStorage.getItem("nombre");
    setNomUser(nom);
  };
  const handleInicio = (e) => {
    e.preventDefault();
    window.location.href = "/";
  };
  const handleEstadisticas = (e) => {
    e.preventDefault();
    window.location.href = "/estadisticas";
  };
  const handleHistorial = (e) => {
    e.preventDefault();
    window.location.href = "/historialcotiz";
  };
  const handleCambiarPass = (e) => {
    e.preventDefault();
    window.location.href = "/resetearcontrasena";
  };
  useEffect(() => {
    saludoCliente();
    componentDidMount();
    setTimeout(() => {
      setIsReadyForCarga(false);
    }, 1370);
  }, []);
  useEffect(() => {
    datosUsuarios();
  });

  const mandarToken = async (e) => {
    let tokenCli = localStorage.getItem("token");
    let tokenNot = localStorage.getItem("tokenNoti");
    let codClie = localStorage.getItem("cod_zh");
    try {
      await axios
        .post(
          TOKEN_NOTI,
          JSON.stringify({
            accion: "actualizaTokenFireBase",
            token: tokenCli,
            cod_cli: codClie,
            token_firebase_notif: tokenNot,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
             if (res.data.status === "false" ||res.data.id_message === "905" || res.data.id_message === "900" ) {
              refreshToken();
            }
          }
        });
    } catch (err) {}
  };

  const activarMensajes = async () => {
    const token = await getToken(messaging, {
      vapidKey:
        "BMWw5bjl1wRMnNJaum67X33pUrUGwp3Dn89G5h8Y2ZlCQPWg4ayO4z-I3TX-sSXKWB9zNuWOQV20cy7bP6wPydk",
    }).catch((error) => {});

    if (token) localStorage.setItem("tokenNoti", token);
  };

  /* Funciones para verificar si hay un token de notificacion existente dentro del localstorage y de la base de datos */

  const verificacionTokenNoti = () => {
    let tokensFire = JSON.parse(localStorage.getItem("tokenFirebase"));
    let tokenNoti = localStorage.getItem("tokenNoti");
    let existeEnBD = false;

    for (var i in tokensFire) {
      let con = tokensFire[i];
      if (con.token_firebase_notif === tokenNoti) {
        existeEnBD = true;
      }
      if (existeEnBD) {
        break;
      }
    }

    if (existeEnBD === true) {
      setIsReadyForNotification(false);
    } else if (existeEnBD === false) {
      setIsReadyForNotification(true);
    }
  };

  /* Funcion para preguntar todos los dias si el usuario desea activar las notificaciones, este mensaje
se deja de preguntar cuando el usuario activa las notificaciones, las bloquea o su dispositivo no las admite
*/

  const mensajeDiario = () => {
    let fechaActual = new Date();
    let _diaActual = fechaActual.toLocaleDateString();
    if (!("Notification" in window)) {
    } else if ("Notification" in window) {
      if (localStorage.getItem("tokenNoti") === null) {
        if (localStorage.getItem("fechaNoti") === null) {
          setTimeout(() => {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              title: "¿Activar Notificaciones?",
              text: "Te notificaremos movimientos en tu cuenta y cambios de la aplicacion.",
              showDenyButton: true,
              denyButtonColor: "#666666",
              confirmButtonText: "Activar",
              confirmButtonColor: "#E85217",
              denyButtonText: `Cancelar`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Comprobamos si los permisos han sido concedidos anteriormente
                if (Notification.permission === "granted") {
                  // Si es correcto, lanzamos una notificación
                }

                // Si no, pedimos permiso para la notificación
                else if (Notification.permission !== "denied") {
                  Notification.requestPermission().then(function (permission) {
                    // Si el usuario nos lo concede, creamos la notificación
                    if (permission === "granted") {
                      // Si es correcto, lanzamos una notificación
                      activarMensajes();
                      Swal.fire({
                        width: "20em",
                        color: "#fff",
                        background: "#2B2B2B",
                        position: "center",
                        icon: "success",
                        title: "Notificaciones Activadas",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  });
                }

                // Por último, si el usuario ha denegado el permiso,
                // y quiere ser respetuoso, no hay necesidad de molestarlo.
              } else {
                let fecha = new Date();
                let _dia = fecha.toLocaleDateString();
                localStorage.setItem("fechaNoti", _dia);
              }
            });
          }, 2700);
        } else if (localStorage.getItem("fechaNoti") < _diaActual) {
          setTimeout(() => {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              title: "¿Activar Notificaciones?",
              text: "Te notificaremos movimientos en tu cuenta y cambios de la aplicacion.",
              showDenyButton: true,
              denyButtonColor: "#666666",
              confirmButtonText: "Activar",
              confirmButtonColor: "#E85217",
              denyButtonText: `Cancelar`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Comprobamos si los permisos han sido concedidos anteriormente
                if (Notification.permission === "granted") {
                  // Si es correcto, lanzamos una notificación
                }

                // Si no, pedimos permiso para la notificación
                else if (Notification.permission !== "denied") {
                  Notification.requestPermission().then(function (permission) {
                    // Si el usuario nos lo concede, creamos la notificación
                    if (permission === "granted") {
                      // Si es correcto, lanzamos una notificación
                      activarMensajes();
                      Swal.fire({
                        width: "20em",
                        color: "#fff",
                        background: "#2B2B2B",
                        position: "center",
                        icon: "success",
                        title: "Notificaciones Activadas",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  });
                }

                // Por último, si el usuario ha denegado el permiso,
                // y quiere ser respetuoso, no hay necesidad de molestarlo.
              } else {
                let fecha = new Date();
                let _dia = fecha.toLocaleDateString();
                localStorage.setItem("fechaNoti", _dia);
              }
            });
          }, 2700);
        }
      } else if (localStorage.getItem("tokenNoti") !== null) {
      }
    }
  };

  const notifyMe = () => {
    Swal.fire({
      width: "20em",
      color: "#fff",
      background: "#2B2B2B",
      title: "¿Activar Notificaciones?",
      text: "Te notificaremos movimientos en tu cuenta y cambios de la aplicacion.",
      showDenyButton: true,
      denyButtonColor: "#666666",
      confirmButtonText: "Activar",
      confirmButtonColor: "#E85217",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Comprobamos si los permisos han sido concedidos anteriormente
        if (Notification.permission === "granted") {
          // Si es correcto, lanzamos una notificación
        }

        // Si no, pedimos permiso para la notificación
        else if (Notification.permission !== "denied") {
          Notification.requestPermission().then(function (permission) {
            // Si el usuario nos lo concede, creamos la notificación
            if (permission === "granted") {
              // Si es correcto, lanzamos una notificación
              activarMensajes();
              Swal.fire({
                width: "20em",
                color: "#fff",
                background: "#2B2B2B",
                position: "center",
                icon: "success",
                title: "Notificaciones Activadas",
                showConfirmButton: false,
                timer: 1500,
              });
              setIsReadyForNotification(false);
              mandarToken();
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    verificacionTokenNoti();
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        setIsReadyForNotification(false);
      }
    }
    comprobarNoti();
  }, []);

  const haldleCerrarExp = (e) => {
    e.preventDefault();
    setExpUnica(false);
    localStorage.setItem("expZucom", "vista");
  };

  const handleMostrarExp = (e) => {
    e.preventDefault();
    setExpUnica(true);
  };
  const handleInstructivo = (e) => {
    e.preventDefault();
    window.location.href = "./instructivoinstalacion";
  };

  const comprobarNoti = () => {
    if ("Notification" in window) {
      if (Notification.permission === "default") {
        setIsReadyForNotification(true);
        mensajeDiario();
      }
      if (Notification.permission === "granted") {
        activarMensajes();
        setIsReadyForNotification(false);
        mandarToken();
      }
      if (Notification.permission === "denied") {
        setIsReadyForNotification(false);
      }
    } else if (!("Notification" in window)) {
      setIsReadyForNotification(false);
    }
  };
  const [iniConDatos, setIniConDatos] = useState(true);
  const [botTrue, setBotTrue] = useState(false);
useEffect(() => {
    if(localStorage.getItem("userDataAcopio") === "1001" || localStorage.getItem("dniCliente") === "99887766" && iniConDatos === true){
      setBotTrue(true);
    }
    const currentURL = new URL(window.location.href);
    const codigoZh = localStorage.getItem("cod_sinc");
    const nombreZh = localStorage.getItem("nombre_sol");
    const apellidoZh = localStorage.getItem("apellido");
    if (iniConDatos === true) {
      currentURL.searchParams.set("i", codigoZh +"_"+ nombreZh+"_"+apellidoZh);
      window.history.pushState({ path: currentURL.href }, '', currentURL.href);
    }
    
  }, [iniConDatos]);

  return (
    <div className="divContenedor">
      {isReadyForCarga && (
        <div className="contHijo">
          <div className="divContenedorHijo">
            <div className="contenedor-loader">
              <div>
                <img src={ImgLogoCarga} className="loader1" alt="logo carga" />
              </div>
            </div>
            <div className="conTxt">
              <span className="txtLoad">ZH Inversiones</span>
            </div>
          </div>
        </div>
      )}
      {expUnica && (
        <div className="contenedorExp">
          <div className="row contExp">
            <div className="contSalirExp">
              <FontAwesomeIcon icon={faXmark} onClick={haldleCerrarExp} />
            </div>
            <div className="contImgExp">
              <CarrucelExp />
            </div>
            <div className="col-12 contBtnOmitir">
              <button className="buttonOmitirExp" onClick={haldleCerrarExp}>
                OMITIR
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid">
        <div className="contWeb">
          <header className="header-inicio">
            <div
              className="row lineaNaraja"
              style={{ backgroundColor: "#E85217" }}
            >
              <div className="col-12" style={{ padding: 8 }}></div>
            </div>
            <div className="row" style={{ width: "100%" }}>
              <div className="col-xl-10 col-lg-10 col-md-8 col-sm-5 col-6">
                <img src={Img} className="img-logo" alt="logo de acopio" />
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-5 col-6">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-dark-example1" variant="light">
                    <FontAwesomeIcon icon={faBars} className="colorIcono" />
                    <span className="txtDropdown">MENU</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="menuDropdown" variant="dark">
                    <Dropdown.Header
                      style={{ color: "#fff" }}
                      className="contNomUser"
                    >
                      <FontAwesomeIcon icon={faUserTie} className="iconoUser" />
                      <span className="txtNom">{nomUser}</span>
                    </Dropdown.Header>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={handleCambiarPass}
                    >
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        className="iconoMov"
                      />
                      <span className="txtMov">Cambiar Contraseña</span>
                    </Dropdown.Item>
                    {isReadyForInstall && (
                      <Dropdown.Item
                        type="button"
                        style={{ color: "#fff" }}
                        onClick={downloadApp}
                      >
                        <FontAwesomeIcon
                          icon={faCircleDown}
                          className="iconoCerrar"
                        />
                        <span>Descargar</span>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={handleEstadisticas}
                    >
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        className="iconoMov"
                      />
                      <span className="txtMov">Estadísticas</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={handleHistorial}
                    >
                      <FontAwesomeIcon
                        icon={faClockRotateLeft}
                        className="iconoMov"
                      />
                      <span className="txtMov">Historial ZUCOM</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={handleInicio}
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="iconoMov"
                      />
                      <span className="txtMov">Volver al Inicio</span>
                    </Dropdown.Item>
                    {isReadyForNotification && (
                      <Dropdown.Item
                        type="button"
                        style={{ color: "#fff" }}
                        onClick={notifyMe}
                      >
                        <FontAwesomeIcon icon={faBell} className="iconoNot" />
                        <span className="txtMov">Activar Notificaciones</span>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={handleMostrarExp}
                    >
                      <FontAwesomeIcon icon={faGem} className="iconoMov" />
                      <span className="txtExpZucom">¿Qué es el ZUCOM?</span>
                    </Dropdown.Item>
                    {isReadyForInstall && (
                      <Dropdown.Item type="button" style={{ color: "#fff" }}>
                        <FontAwesomeIcon
                          icon={faFileInvoiceDollar}
                          className="iconoMov"
                        />
                        <span
                          className="txtInstruc"
                          onClick={handleInstructivo}
                        >
                          Instructivo de Instalación
                        </span>
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={Logout}
                    >
                      <FontAwesomeIcon
                        icon={faArrowRightFromBracket}
                        className="iconoCerrar"
                      />
                      <span>Cerrar Sesión</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </header>
          <main style={backgroundImageMain}>
          {
              botTrue && (
                <ArtiBotComponent />
              )
            }
            <div className="tituloMov">
              <span className="titulomovimiento">
                MOVIMIENTOS DE TU INVERSIÓN
              </span>
            </div>
            <div className="row justify-content-center contenedorTabla">
              <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 col-12 table-responsive contTabla">
                <div className="table-wrapper">
                  <table
                    className="table table-dark table-hover table-responsive table-striped"
                    id="grilla"
                  >
                    <thead>
                      <tr>
                        <th
                          className="contenDes text-center"
                          style={{ color: "#fff", backgroundColor: "#E85217" }}
                        >
                          DETALLE MOVIM.
                        </th>
                        <th
                          className=" contMonto"
                          style={{ color: "#fff", backgroundColor: "#E85217" }}
                        >
                          MONTO/ZUCOM
                        </th>
                        <th
                          className="contCant text-center"
                          style={{ color: "#fff", backgroundColor: "#E85217" }}
                        >
                          CANT. ZUCOM
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{ backgroundColor: " #333333" }}
                      name="datosCompra"
                      id="datosCompra"
                    >
                      {filteredData.map((datos) => (
                        <tr key={datos.codigo}>
                          <td className="text-left contDetall">
                            {Number(datos.cant_acopio) < 0 ? (
                              <span className="descAcop">
                                <a
                                  onClick={() => handleComprobante(datos)}
                                  className="linkCompro"
                                  style={{
                                    all: "initial !important",
                                    animation: "none !important",
                                    transition: "none !important",
                                  }}
                                >
                                  Compra #{datos.codigo}{" "}
                                  <FontAwesomeIcon
                                    className="iconInfoDes"
                                    icon={faCircleInfo}
                                  />
                                </a>
                              </span>
                            ) : (
                              <span className="descAcop">
                                <a
                                  onClick={() => handleComprobante(datos)}
                                  className="linkCompro"
                                  style={{
                                    all: "initial !important",
                                    animation: "none !important",
                                    transition: "none !important",
                                  }}
                                >
                                  {Number(datos.bonificacion) !== 0 ? (
                                    <FontAwesomeIcon
                                     style={{paddingRight:"5px", color:"#E85217"}}
                                      className="iconInfoDes"
                                      icon={faGift}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {Number(datos.bonificacion) !== 0
                                    ? "Bonificación"
                                    : "Inversión"}{" "}
                                  #{datos.codigo}{" "}
                                  {Number(datos.bonificacion) !== 0 ? (
                                    ""
                                  ) : (
                                    <FontAwesomeIcon
                                      className="iconInfoDes"
                                      icon={faCircleInfo}
                                    />
                                  )}
                                </a>
                              </span>
                            )}{" "}
                            <br />{" "}
                            <span className="fechaTabla">{datos.fecha}</span>
                          </td>
                          <td className=" text-center">
                            <span
                              className={
                                Number(datos.cant_acopio) < 0
                                  ? "negativo"
                                  : "clase-inicial"
                              }
                            >
                              {new Intl.NumberFormat("es-AR", {
                                style: "currency",
                                currency: "ARS",
                              }).format(datos.tot_acopiado_inicio)}
                            </span>{" "}
                            <br />{" "}
                            <span className="varTabla">
                              {new Intl.NumberFormat("es-AR", {
                                style: "currency",
                                currency: "ARS",
                              }).format(datos.cotiz_acopio)}
                            </span>
                          </td>
                          <td className=" text-center tdUlt">
                            <span
                              className={
                                Number(datos.cant_acopio) < 0
                                  ? "negativo"
                                  : "clase-inicial"
                              }
                            >
                              {Number(datos.cant_acopio).toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="row justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 col-12">
                {varPagination()}
              </div>
            </div>
            <div className="row contenedorCoins" style={{ width: "100%" }}>
              <div className="col-12 container-img">
                <FontAwesomeIcon icon={faGem} className="iconDiamante" />
                <h2 className="cantCoins" id="cantAcopio">
                  {" "}
                  .{" "}
                </h2>
                <h2 className="tituloCoin" id="nomCoin">
                  {" "}
                  .{" "}
                </h2>
              </div>
            </div>
            <div
              className="row contenedorVolerInicio"
              style={{ width: "100%" }}
            >
              <div className="col-12 containerMov">
                <a className="colorBlan" href="/" onClick={handleInicio}>
                  <FontAwesomeIcon icon={faArrowLeft} className="iconoMov" />
                  <span className="txtMov">Volver al Inicio</span>
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Movimientos;
