import React from 'react'

const Tabla = ({
    columns,
    datosCotiz
}) => {
  return (
    <div className="row justify-content-center contenedorTablaAnual">
    <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 col-12 table-responsive contTabla">
      <div className="table-wrapper">
        <table
          className="table table-dark table-hover table-responsive table-striped"
          id="grilla"
        >
          <thead>
            <tr>
            {columns &&
                    columns.map((column) => (
                      <th
                        style={{ color: "#fff", backgroundColor: "#E85217" }}
                        key={column}
                      >
                        {column}
                      </th>
                    ))}
            </tr>
          </thead>
          <tbody
            style={{ backgroundColor: " #333333" }}
            name="datosCompra"
            id="datosCompra"
          >
            {Array.isArray(datosCotiz) ? (
                  datosCotiz.map((datos) => (
                    <tr key={datos.Nombre}>
                      <td className="text-left">{datos.Nombre}</td>
                      <td className="text-center">
                        {new Intl.NumberFormat("es-AR", {
                          style: "currency",
                          currency: "ARS",
                        }).format(datos.Inicial)}
                      </td>
                      <td className="text-center">
                        {new Intl.NumberFormat("es-AR", {
                          style: "currency",
                          currency: "ARS",
                        }).format(datos.Actual)}
                      </td>
                      <td className="text-center">{datos.Variacion}% </td>
                    </tr>
                  ))
                ) : (
                  // Si datosCotiz no es un array, puedes mostrar un mensaje o cualquier otro contenido
                  <tr>
                    <td colSpan={4}>No hay datos para mostrar</td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default Tabla