import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Img from "../assets/logo-zuppa-hermanos-acopio.png";
import ImgLogoCarga from "../assets/logo_con_trazo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faArrowLeft,
  faFileInvoiceDollar,
  faArrowRightFromBracket,
  faCircleDown,
  faBars,
  faAnglesLeft,
  faAnglesRight,
  faPenToSquare,
  faGem,
  faXmark,
  faBell,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import ImgFondo from "../assets/Dark_background_1920x1080.png";
//import MixedChart from "../components/grafico";
import "../css/style.css";
import axios from "axios";
import "styled-components";
import CarrucelExp from "../components/CarrucelExp";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase";
import ArtiBotComponent from "../components/chatbot/ArtiBotComponet";

const backgroundImageMain = {
  backgroundImage: `url(${ImgFondo})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  width: "100%",
  minHeight: "100vh",
  height: "100%",
};

const DATOS_ACOPIO = "https://www.zuppahermanos.com.ar/virtual/api/acopio.php";

const REFRESH_TOKEN =
  "https://www.zuppahermanos.com.ar/virtual/api/refreshTokenCliente.php";

const TOKEN_NOTI = "https://www.zuppahermanos.com.ar/virtual/api/acopio.php";

const NUM_ELEMENTOS_POR_PAGINA = 20;

const Historial = () => {
  const [datosVarFe, setDatosVarFe] = useState([]);
  const [feDesde, setFeDesde] = useState("");
  const [feHasta, setFeHasta] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [isReadyForCarga, setIsReadyForCarga] = useState(true);
  const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);
  const [nomUser, setNomUser] = useState("");
  const [isReadyForNotification, setIsReadyForNotification] = useState(false);
  const [expUnica, setExpUnica] = useState(false);
  const [openGrilla, setOpenGrilla] = useState(false);
  const [iniConDatos, setIniConDatos] = useState(true);
  const [botTrue, setBotTrue] = useState(false);

  const Logout = () => {
    localStorage.removeItem("userDataAcopio");
    localStorage.removeItem("token");
    localStorage.removeItem("cod_zh");
    localStorage.removeItem("cod_sinc");
    localStorage.removeItem("nombre");
    localStorage.removeItem("apellido");
    window.location.href = "/";
  };

  

  const refreshToken = async (e) => {
    let tokeen = localStorage.getItem("token");
    try {
      const res = await axios.post(
        REFRESH_TOKEN,
        JSON.stringify({
          token: tokeen,
        }),
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (res.data.status === "true") {
        let tooken = res.data.token;
        localStorage.setItem("token", tooken);
        datosCotizFec();
      } else if (res.data.status === "false") {
        localStorage.setItem("token", "");
        window.location.href = "./";
      }
    } catch (error) {}
  };

  const datosCotizFec = async (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    if (feDesde > feHasta) {
      Swal.fire({
        width: "20em",
        color: "#fff",
        background: "#2B2B2B",
        text: "La fecha ingresada en 'Hasta' no puede ser menor a la ingresada en 'Desde'.",
        confirmButtonText: "Ok",
        confirmButtonColor: "#E85217",
      });
    }
    if (feDesde === "" && feHasta === "") {
      Swal.fire({
        width: "20em",
        color: "#fff",
        background: "#2B2B2B",
        text: "Debe ingresar una fecha valida.",
        confirmButtonText: "Ok",
        confirmButtonColor: "#E85217",
      });
    } else {
      Swal.fire({
        width: "20em",
        color: "#fff",
        icon: "success",
        background: "#2B2B2B",
        text: "Datos ingresados con éxito!",
        showConfirmButton: false,
        timer: 1500,
      });
      try {
        await axios
          .post(
            DATOS_ACOPIO,
            JSON.stringify({
              accion: "historialZucom",
              f_ini: feDesde,
              f_fin: feHasta,
              token: token,
            }),
            {
              headers: {
                "Content-type": "text/html; charset=UTF-8",
              },
            }
          )
          .then((res) => {
            if (res.data.status === "true") {
               if (res.data.id_message === 200) {
                let resp = res.data.message;
                if (resp.ZUCOM.length > 0) {
                  setDatosVarFe(resp.ZUCOM);
                  setOpenGrilla(true);
                  const totalPages = Math.ceil(
                    resp.ZUCOM.length / NUM_ELEMENTOS_POR_PAGINA
                  );
                  setTotalPages(totalPages);
                } else {
                  setOpenGrilla(true);
                  setDatosVarFe(resp.ZUCOM_ULT);
                  const totalPages = Math.ceil(
                    resp.ZUCOM_ULT.length / NUM_ELEMENTOS_POR_PAGINA
                  );
                  setTotalPages(totalPages);
                }
              }else if (res.data.status === "false" ||res.data.id_message === "905" || res.data.id_message === "900" ) {
                refreshToken();
              }
            }
          });
      } catch (error) {}
    }
  };
  const filteredData = datosVarFe.slice(
    (currentPage - 1) * NUM_ELEMENTOS_POR_PAGINA,
    currentPage * NUM_ELEMENTOS_POR_PAGINA
  );

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    const totalPages = Math.ceil(datosVarFe.length / NUM_ELEMENTOS_POR_PAGINA);
    setTotalPages(totalPages);
    const newStartIndex = (currentPage - 1) * NUM_ELEMENTOS_POR_PAGINA;
    const newEndIndex = Math.min(
      newStartIndex + NUM_ELEMENTOS_POR_PAGINA,
      datosVarFe.length
    );
    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  }, [datosVarFe, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const varPagination = () => {
    if (datosVarFe.length > 5) {
      return (
        <div className="row">
          <div className="col-12 text-center contPaginacion">
            <div>
              <div className="pagination">
                <button
                  className="btn btnSig btnChico btnRed"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <FontAwesomeIcon
                    icon={faAnglesLeft}
                    className="colorIconoBtn"
                  />
                </button>
                <span className="txtPaginacion">
                  Mostrando {startIndex + 1} - {endIndex} de {datosVarFe.length}{" "}
                </span>
                <button
                  className="btn btnSig btnChico btnRed"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <FontAwesomeIcon
                    icon={faAnglesRight}
                    className="colorIconoBtn"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (datosVarFe.length < 5) {
      return (
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 contTxtPag">
            <div>
              <p className="txtPaginacion">
                Mostrando {startIndex + 1} - {endIndex} de {datosVarFe.length}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    setFeHasta(fechaActual);
    setFeDesde(fechaActualStr);
  }, []);

  // Función para manejar el cambio en el input "feDesde"
  const handleFeDesdeChange = (event) => {
    setFeDesde(event.target.value);
  };

  // Función para manejar el cambio en el input "feHasta"
  const handleFeHastaChange = (event) => {
    setFeHasta(event.target.value);
  };

  // Obtener la fecha actual en formato yyyy-mm-dd
  const fechaActual = new Date().toISOString().split("T")[0];

  const fechaInicio = new Date();
  fechaInicio.setDate(1);
  // Iniciar en este año, este mes, en el día 1
  const fechaActualStr = fechaInicio.toISOString().split("T")[0];

  const saludoCliente = () => {
    let nom = localStorage.getItem("nombre");
    setNomUser(nom);
  };

  useEffect(() => {
    saludoCliente();
    setTimeout(() => {
      setIsReadyForCarga(false);
    }, 1370);
  }, []);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);
  async function downloadApp() {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }

  const handleInicio = (e) => {
    e.preventDefault();
    window.location.href = "/";
  };
  const handleGrilla = (e) => {
    e.preventDefault();
    window.location.href = "/movimientos";
  };
  const handleEstadisticas = (e) => {
    e.preventDefault();
    window.location.href = "/estadisticas";
  };
  const handleCambiarPass = (e) => {
    e.preventDefault();
    window.location.href = "/resetearcontrasena";
  };

  const mandarToken = async (e) => {
    let tokenCli = localStorage.getItem("token");
    let tokenNot = localStorage.getItem("tokenNoti");
    let codClie = localStorage.getItem("cod_zh");
    try {
      await axios
        .post(
          TOKEN_NOTI,
          JSON.stringify({
            accion: "actualizaTokenFireBase",
            token: tokenCli,
            cod_cli: codClie,
            token_firebase_notif: tokenNot,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
             if (res.data.status === "false" ||res.data.id_message === "905" || res.data.id_message === "900" ) {
              refreshToken();
            } 
          }
        });
    } catch (err) {}
  };

  const activarMensajes = async () => {
    const token = await getToken(messaging, {
      vapidKey:
        "BMWw5bjl1wRMnNJaum67X33pUrUGwp3Dn89G5h8Y2ZlCQPWg4ayO4z-I3TX-sSXKWB9zNuWOQV20cy7bP6wPydk",
    }).catch((error) => error);

    if (token) localStorage.setItem("tokenNoti", token);
    if (!token);
  };

  /* Funciones para verificar si hay un token de notificacion existente dentro del localstorage y de la base de datos */

  const verificacionTokenNoti = () => {
    let tokensFire = JSON.parse(localStorage.getItem("tokenFirebase"));
    let tokenNoti = localStorage.getItem("tokenNoti");
    let existeEnBD = false;

    for (var i in tokensFire) {
      let con = tokensFire[i];
      if (con.token_firebase_notif === tokenNoti) {
        existeEnBD = true;
      }
      if (existeEnBD) {
        break;
      }
    }

    if (existeEnBD === true) {
      setIsReadyForNotification(false);
    } else if (existeEnBD === false) {
      setIsReadyForNotification(true);
    }
  };

  /* Funcion para preguntar todos los dias si el usuario desea activar las notificaciones, este mensaje
se deja de preguntar cuando el usuario activa las notificaciones, las bloquea o su dispositivo no las admite
*/

  const mensajeDiario = () => {
    let fechaActual = new Date();
    let _diaActual = fechaActual.toLocaleDateString();
    if (!("Notification" in window)) {
    } else if ("Notification" in window) {
      if (localStorage.getItem("tokenNoti") === null) {
        if (localStorage.getItem("fechaNoti") === null) {
          setTimeout(() => {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              title: "¿Activar Notificaciones?",
              text: "Te notificaremos movimientos en tu cuenta y cambios de la aplicacion.",
              showDenyButton: true,
              denyButtonColor: "#666666",
              confirmButtonText: "Activar",
              confirmButtonColor: "#E85217",
              denyButtonText: `Cancelar`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Comprobamos si los permisos han sido concedidos anteriormente
                if (Notification.permission === "granted") {
                  // Si es correcto, lanzamos una notificación
                }

                // Si no, pedimos permiso para la notificación
                else if (Notification.permission !== "denied") {
                  Notification.requestPermission().then(function (permission) {
                    // Si el usuario nos lo concede, creamos la notificación
                    if (permission === "granted") {
                      // Si es correcto, lanzamos una notificación
                      activarMensajes();
                      Swal.fire({
                        width: "20em",
                        color: "#fff",
                        background: "#2B2B2B",
                        position: "center",
                        icon: "success",
                        title: "Notificaciones Activadas",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  });
                }

                // Por último, si el usuario ha denegado el permiso,
                // y quiere ser respetuoso, no hay necesidad de molestarlo.
              } else {
                let fecha = new Date();
                let _dia = fecha.toLocaleDateString();
                localStorage.setItem("fechaNoti", _dia);
              }
            });
          }, 2700);
        } else if (localStorage.getItem("fechaNoti") < _diaActual) {
          setTimeout(() => {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              title: "¿Activar Notificaciones?",
              text: "Te notificaremos movimientos en tu cuenta y cambios de la aplicacion.",
              showDenyButton: true,
              denyButtonColor: "#666666",
              confirmButtonText: "Activar",
              confirmButtonColor: "#E85217",
              denyButtonText: `Cancelar`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Comprobamos si los permisos han sido concedidos anteriormente
                if (Notification.permission === "granted") {
                  // Si es correcto, lanzamos una notificación
                }

                // Si no, pedimos permiso para la notificación
                else if (Notification.permission !== "denied") {
                  Notification.requestPermission().then(function (permission) {
                    // Si el usuario nos lo concede, creamos la notificación
                    if (permission === "granted") {
                      // Si es correcto, lanzamos una notificación
                      activarMensajes();
                      Swal.fire({
                        width: "20em",
                        color: "#fff",
                        background: "#2B2B2B",
                        position: "center",
                        icon: "success",
                        title: "Notificaciones Activadas",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  });
                }

                // Por último, si el usuario ha denegado el permiso,
                // y quiere ser respetuoso, no hay necesidad de molestarlo.
              } else {
                let fecha = new Date();
                let _dia = fecha.toLocaleDateString();
                localStorage.setItem("fechaNoti", _dia);
              }
            });
          }, 2700);
        }
      } else if (localStorage.getItem("tokenNoti") !== null) {
      }
    }
  };

  const notifyMe = () => {
    Swal.fire({
      width: "20em",
      color: "#fff",
      background: "#2B2B2B",
      title: "¿Activar Notificaciones?",
      text: "Te notificaremos movimientos en tu cuenta y cambios de la aplicacion.",
      showDenyButton: true,
      denyButtonColor: "#666666",
      confirmButtonText: "Activar",
      confirmButtonColor: "#E85217",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Comprobamos si los permisos han sido concedidos anteriormente
        if (Notification.permission === "granted") {
          // Si es correcto, lanzamos una notificación
        }

        // Si no, pedimos permiso para la notificación
        else if (Notification.permission !== "denied") {
          Notification.requestPermission().then(function (permission) {
            // Si el usuario nos lo concede, creamos la notificación
            if (permission === "granted") {
              // Si es correcto, lanzamos una notificación
              activarMensajes();
              Swal.fire({
                width: "20em",
                color: "#fff",
                background: "#2B2B2B",
                position: "center",
                icon: "success",
                title: "Notificaciones Activadas",
                showConfirmButton: false,
                timer: 1500,
              });
              setIsReadyForNotification(false);
              mandarToken();
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    verificacionTokenNoti();
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        setIsReadyForNotification(false);
      }
    }
    comprobarNoti();
  }, []);

  const haldleCerrarExp = (e) => {
    e.preventDefault();
    setExpUnica(false);
    localStorage.setItem("expZucom", "vista");
  };

  const handleMostrarExp = (e) => {
    e.preventDefault();
    setExpUnica(true);
  };
  const handleInstructivo = (e) => {
    e.preventDefault();
    window.location.href = "./instructivoinstalacion";
  };

  const comprobarNoti = () => {
    if ("Notification" in window) {
      if (Notification.permission === "default") {
        setIsReadyForNotification(true);
        mensajeDiario();
      }
      if (Notification.permission === "granted") {
        activarMensajes();
        setIsReadyForNotification(false);
        mandarToken();
      }
      if (Notification.permission === "denied") {
        setIsReadyForNotification(false);
      }
    } else if (!("Notification" in window)) {
      setIsReadyForNotification(false);
    }
  };
  useEffect(() => {
    if(localStorage.getItem("userDataAcopio") === "1001" || localStorage.getItem("dniCliente") === "99887766" && iniConDatos === true){
      setBotTrue(true);
    }
    const currentURL = new URL(window.location.href);
    const codigoZh = localStorage.getItem("cod_sinc");
    const nombreZh = localStorage.getItem("nombre_sol");
    const apellidoZh = localStorage.getItem("apellido");
    if (iniConDatos === true) {
      currentURL.searchParams.set("i", codigoZh +"_"+ nombreZh+"_"+apellidoZh);
      window.history.pushState({ path: currentURL.href }, '', currentURL.href);
    }
    
  }, [iniConDatos]);

  return (
    <div className="container-fluid">
      {isReadyForCarga && (
        <div className="contHijo">
          <div className="divContenedorHijo">
            <div className="contenedor-loader">
              <div>
                <img src={ImgLogoCarga} className="loader1" alt="log carga" />
              </div>
            </div>
            <div className="conTxt">
              <span className="txtLoad">ZH Inversiones</span>
            </div>
          </div>
        </div>
      )}
      {expUnica && (
        <div className="contenedorExp">
          <div className="row contExp">
            <div className="contSalirExp">
              <FontAwesomeIcon icon={faXmark} onClick={haldleCerrarExp} />
            </div>
            <div className="contImgExp">
              <CarrucelExp />
            </div>
            <div className="col-12 contBtnOmitir">
              <button className="buttonOmitirExp" onClick={haldleCerrarExp}>
                OMITIR
              </button>
            </div>
          </div>
        </div>
      )}
      <header className="header-inicio">
        <div className="conteiner-fluid">
          <div className="row" style={{ backgroundColor: "#E85217" }}>
            <div className="col-12" style={{ padding: 8 }}></div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-10 col-lg-10 col-md-8 col-sm-5 col-6">
            <img src={Img} className="img-logo" alt="logo de acopio" />
          </div>
          <div className="col-xl-2 col-lg-2 col-md-4 col-sm-5 col-6">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-dark-example1" variant="light">
                <FontAwesomeIcon icon={faBars} className="colorIcono" />
                <span className="txtDropdown">MENU</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="menuDropdown" variant="dark">
                <Dropdown.Header
                  style={{ color: "#fff" }}
                  className="contNomUser"
                >
                  <FontAwesomeIcon icon={faUserTie} className="iconoUser" />
                  <span className="txtNom">{nomUser}</span>
                </Dropdown.Header>
                <Dropdown.Divider />
                <Dropdown.Item
                  type="button"
                  style={{ color: "#fff" }}
                  onClick={handleCambiarPass}
                >
                  <FontAwesomeIcon icon={faPenToSquare} className="iconoMov" />
                  <span className="txtMov">Cambiar Contraseña</span>
                </Dropdown.Item>
                {isReadyForInstall && (
                  <Dropdown.Item
                    type="button"
                    style={{ color: "#fff" }}
                    onClick={downloadApp}
                  >
                    <FontAwesomeIcon
                      icon={faCircleDown}
                      className="iconoCerrar"
                    />
                    <span>Descargar</span>
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  type="button"
                  style={{ color: "#fff" }}
                  onClick={handleGrilla}
                >
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    className="iconoMov"
                  />
                  <span className="txtMov">Movimientos</span>
                </Dropdown.Item>
                <Dropdown.Item
                  type="button"
                  style={{ color: "#fff" }}
                  onClick={handleEstadisticas}
                >
                  <FontAwesomeIcon icon={faChartSimple} className="iconoMov" />
                  <span className="txtMov">Estadísticas</span>
                </Dropdown.Item>
                <Dropdown.Item
                  type="button"
                  style={{ color: "#fff" }}
                  onClick={handleInicio}
                >
                  <FontAwesomeIcon icon={faArrowLeft} className="iconoMov" />
                  <span className="txtMov">Volver al Inicio</span>
                </Dropdown.Item>
                {isReadyForNotification && (
                  <Dropdown.Item
                    type="button"
                    style={{ color: "#fff" }}
                    onClick={notifyMe}
                  >
                    <FontAwesomeIcon icon={faBell} className="iconoNot" />
                    <span className="txtMov">Activar Notificaciones</span>
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  type="button"
                  style={{ color: "#fff" }}
                  onClick={handleMostrarExp}
                >
                  <FontAwesomeIcon icon={faGem} className="iconoMov" />
                  <span className="txtExpZucom">¿Qué es el ZUCOM?</span>
                </Dropdown.Item>
                {isReadyForInstall && (
                  <Dropdown.Item type="button" style={{ color: "#fff" }}>
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      className="iconoMov"
                    />
                    <span className="txtInstruc" onClick={handleInstructivo}>
                      Instructivo de Instalación
                    </span>
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  type="button"
                  style={{ color: "#fff" }}
                  onClick={Logout}
                >
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    className="iconoCerrar"
                  />
                  <span>Cerrar Sesión</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </header>
      <main style={backgroundImageMain}>
      {
              botTrue && (
                <ArtiBotComponent />
              )
            }
        <div className="tituloMov">
          <span className="titulomovimiento">HISTORIAL COTIZACIÓN ZUCOM</span>
        </div>
        <form>
          <div className="row justify-content-center">
            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-5 col-7">
              <label
                className="form-label"
                style={{ color: "#fff" }}
                for="feDesde"
              >
                Desde
              </label>
              <input
                className="form-control"
                id="feDesde"
                type="date"
                min="2023-01-01"
                max={fechaActual} // Establecer la fecha máxima como la fecha actual
                value={feDesde}
                onChange={handleFeDesdeChange}
              />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-5 col-7">
              <label
                className="form-label"
                style={{ color: "#fff" }}
                for="feHasta"
              >
                Hasta
              </label>
              <input
                className="form-control"
                id="feHasta"
                type="date"
                value={feHasta}
                onChange={handleFeHastaChange}
                min={feDesde} // Establecer la fecha mínima según el valor de "feDesde"
                max={fechaActual} // Establecer la fecha máxima como la fecha actual
              />
            </div>
          </div>
          <div
            className="row justify-content-center"
            style={{ paddingTop: "15px" }}
          >
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 col-7 contBotInicio">
              <button className="btn btnGraf" onClick={datosCotizFec}>
                Buscar
              </button>
            </div>
          </div>
        </form>
        {openGrilla && (
          <>
            <div className="row justify-content-center contenedorTablaEst">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8 col-10 table-responsive contTabla">
                <div className="table-wrapper">
                  <table
                    className="table table-dark table-hover table-responsive table-striped"
                    id="grilla"
                  >
                    <thead>
                      <tr>
                        <th
                          className="contenDes text-center"
                          style={{ color: "#fff", backgroundColor: "#E85217" }}
                        >
                          Fecha
                        </th>
                        <th
                          className=" contMonto"
                          style={{ color: "#fff", backgroundColor: "#E85217" }}
                        >
                          Cotización
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{ backgroundColor: " #333333" }}
                      name="datosCompra"
                      id="datosCompra"
                    >
                      {filteredData.map((datos) => (
                        <tr key={datos.codigo}>
                          <td
                            className="text-center "
                            style={{ fontSize: "16px" }}
                          >
                            {datos.fecha}
                          </td>
                          <td
                            className=" text-center "
                            style={{ fontSize: "16px" }}
                          >
                            $ {datos.cotiz}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="row justify-content-center"
              style={{ width: "100%", paddingBottom: "15px" }}
            >
              <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 col-12">
                {varPagination()}
              </div>
            </div>
          </>
        )}
        <div className="col-12 containerMov">
          <a className="colorBlan" href="/" onClick={handleInicio}>
            <FontAwesomeIcon icon={faArrowLeft} className="iconoMov" />
            <span className="txtMov">Volver al Inicio</span>
          </a>
        </div>
      </main>
    </div>
  );
};

export default Historial;
