import React from 'react'

const TablaInterAnual = ({
    columns,
    datosCotiz
}) => {
  return (
    <div className="row justify-content-center contenedorTablaAnual">
    <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 col-12 table-responsive contTabla">
      <div className="table-wrapper">
        <table
          className="table table-dark table-hover table-responsive table-striped"
          id="grilla"
        >
          <thead>
            {Array.isArray(columns) ? (
                  columns.map((datos) => (
                    <tr key={datos.Titulo}>
                      <th style={{ color: "#fff", backgroundColor: "#E85217" }} colSpan={4} className="text-left">{datos.Titulo}</th>
                      <th style={{ color: "#fff", backgroundColor: "#E85217", width:"174px" }} className="text-center">{datos.Varia} </th>
                    </tr>
                  ))
                ) : (
                  // Si datosCotiz no es un array, puedes mostrar un mensaje o cualquier otro contenido
                  <tr>
                    <th tdcolSpan={4}>No hay datos para mostrar</th>
                  </tr>
                )}
          </thead>
          <tbody
            style={{ backgroundColor: " #333333" }}
            name="datosCompra"
            id="datosCompra"
          >
            {Array.isArray(datosCotiz) ? (
                  datosCotiz.map((datos) => (
                    <tr key={datos.Nombre}>
                      <td colSpan={4} className="text-left">{datos.Nombre}</td>
                      <td className="text-center">{datos.Variacion}% </td>
                    </tr>
                  ))
                ) : (
                  // Si datosCotiz no es un array, puedes mostrar un mensaje o cualquier otro contenido
                  <tr>
                    <td colSpan={4}>No hay datos para mostrar</td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )
}

export default TablaInterAnual