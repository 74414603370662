

let getFecha = (_tipo, _separador) => {
    var fecha = new Date();
    var _dia = fecha.getUTCDate();
    if ((_dia >= 0) && (_dia < 10)) {
        _dia = "0".concat(_dia);
    }
    var _mm = fecha.getUTCMonth() + 1;
    var _mes = _mm;
    if ((_mes >= 0) && (_mes < 10)) {
        _mes = "0".concat(_mes);
    }
    var _ano = fecha.getUTCFullYear();

    var _hora = fecha.getHours();
    if ((_hora >= 0) && (_hora < 10)) {
        _hora = "0".concat(_hora);
    }
    var _minutos = fecha.getMinutes();
    if ((_minutos >= 0) && (_minutos < 10)) {
        _minutos = "0".concat(_minutos);
    }
    var diaSemana = new Array(["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"]);
    //var diaSemCorto = new Array(["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"]);
    var meses = new Array(["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]);
    var mesesCorto = new Array(["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Agos", "Sep", "Oct", "Nov", "Dic"]);
    if (_tipo === 'FCORTA') { // 10-11-2020
        return _dia + _separador + _mes + _separador + _ano;
    } else if (_tipo === 'FCORTAREV') { // 2020-11-10
        return _ano + _separador + _mes + _separador + _dia;
    } else if (_tipo === 'FYHORA') { // 10-11-2020 11:20
        return _dia + _separador + _mes + _separador + _ano + ' ' + _hora + ':' + _minutos;
    } else if (_tipo === 'FYHORAREV') { // 2020-11-10
        return _ano + _separador + _mes + _separador + _dia + ' ' + _hora + ':' + _minutos;
    } else if (_tipo === 'FCORTANOMBRES') {
        return diaSemana[fecha.getDay()] + ' ' + _dia + ' ' + meses[_mm - 1] + ' ' + _ano;
    } else if (_tipo === 'FCORTANOMBREMES') {
        return _dia + ' ' + meses[_mm - 1] + ' ' + _ano;
    } else if (_tipo === 'FCORTANOMBREMESICC') {
        return _dia + ' ' + meses[_mm - 3] + ' ' + _ano;
    } else if (_tipo === 'NUMEMES') {
        return _mm;
    } else if (_tipo === 'NUMANO') {
        return _ano;
    } else if (_tipo === 'MESANO') {
        return mesesCorto[_mm - 3] + ' ' + _ano;
    } else if (_tipo === 'NOMMES') {
        return mesesCorto[_mm - 1];
    } else if (_tipo === 'NOMMESGRAFUNO') {
        return mesesCorto[_mm - 1];
    } else if (_tipo === 'NOMMESGRAFDOS') {
        return mesesCorto[_mm + 10];
    } else if (_tipo === 'NOMMESGRAFTRES') {
        return mesesCorto[_mm + 9];
    } else if (_tipo === 'NOMMESGRAFCUAT') {
        return mesesCorto[_mm + 8];
    } else if (_tipo === 'NOMMESGRAFCIN') {
        return mesesCorto[_mm + 7];
    } else if (_tipo === 'NOMMESGRAFSEIS') {
        return mesesCorto[_mm + 6];
    }



};

export default getFecha;



