import axios from "axios";
import { useEffect } from "react";
import getFecha from "./fecha";

const urlApiDolar = 'https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerCotizacionDolar&mes='.concat(getFecha("NUMEMES", ""), '&ano=', getFecha("NUMANO", ""));

const ValDolar = () => {


    const apiDolar = async (e) => {
        try {
            await axios.get(urlApiDolar)
                .then(res => res.data)
                .then(res => mostrarData(res))
        } catch (error) {

        }
    }

    useEffect(() => {
        apiDolar();
    },[]);

    const mostrarData = (data) => {
        //console.log(data)
        let valorDolar = ""
        let valDolar = ""
        for (var i = 0; i < data.length; i++) {
            valorDolar += `${data[i].cotizacion}`
            valDolar = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(valorDolar);
        }
        return document.getElementById('valDolar').innerHTML = (`${valDolar}`);
    }
}

export default ValDolar;
