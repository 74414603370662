import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import Img from "../assets/logo-zuppa-hermanos-acopio.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleDown,
  faBars,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import "../css/style.css";
import "../css/load.css";
import CarrucelAndroid from "../components/CarrucelAndroid";
import CarrucelIphone from "../components/CarrucelIphone";

const backgroundImageMain = {
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100vh",
};

const InstructivoIntalacion = () => {
  const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);
  const [primeraPant, setPrimeraPant] = useState(true);
  const [androidIntruc, setAndroidInstruc] = useState(false);
  const [iphoneIntruc, setIphoneInstruc] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);
  async function downloadApp() {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }
  const handleInicio = (e) => {
    e.preventDefault();
    window.location.href = "/";
  };

  const handleAndroid = (e) => {
    e.preventDefault();
    setAndroidInstruc(true);
    setIphoneInstruc(false);
    setPrimeraPant(false);
  };

  const handleIphone = (e) => {
    e.preventDefault();
    setIphoneInstruc(true);
    setAndroidInstruc(false);
    setPrimeraPant(false);
  };
  const handleDispositivos = (e) => {
    e.preventDefault();
    setIphoneInstruc(false);
    setAndroidInstruc(false);
    setPrimeraPant(true);
  };

  return (
    <div className="divContenedor">
      <div className="contWeb">
        <header className="header-inicio">
          <div className="conteiner-fluid">
            <div
              className="row lineaNaraja"
              style={{ backgroundColor: "#E85217" }}
            >
              <div className="col-12" style={{ padding: 8 }}></div>
            </div>
          </div>
          <div className="row contHeader">
            <div className="col-xl-10 col-lg-10 col-md-8 col-sm-5 col-5 contLogo">
              <img src={Img} className="img-logo" alt="logo de acopio" />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-4 col-sm-5 col-6 contDrp">
              <Dropdown className="boxDrop">
                <Dropdown.Toggle id="dropdown-dark-example1" variant="light">
                  <FontAwesomeIcon icon={faBars} className="colorIcono" />
                  <span className="txtDropdown">MENU</span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="menuDropdown" variant="dark">
                  {isReadyForInstall && (
                    <Dropdown.Item
                      type="button"
                      style={{ color: "#fff" }}
                      onClick={downloadApp}
                    >
                      <FontAwesomeIcon
                        icon={faCircleDown}
                        className="iconoMov"
                      />
                      <span className="txtMov">Descargar</span>
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    type="button"
                    style={{ color: "#fff" }}
                    onClick={handleInicio}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="iconoMov" />
                    <span className="txtInstruc">Volver Inicio</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </header>
        <main style={backgroundImageMain}>
          {primeraPant && (
            <div className="row contTextosDesc">
              <div className="col-12 col-xl-4 col-lg-4 col-sm-4 contPrimerTex">
                <div className="row contGeneralIntruc">
                  <div className="col-11 contTxtCels">
                    <span className="txtDescar">
                      DESCARGÁ{" "}
                      <span className="txtZhInver">ZH Inversiones.</span>
                    </span>
                  </div>
                  <div className="col-11 contEmpc">
                    <span className="txtEmpc">
                      Empecemos eligiendo tu dispositivo.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contCelulares">
                <div className="row contGeneralIntruc">
                  <div className="col-6 ">
                    <div className="contenedorImgCelularUno">
                      <img
                        src={require("../assets/Android/Android 00.png")}
                        className="celusUno"
                        onClick={handleAndroid}
                      />
                    </div>
                    <div className="col-12 contBtnDisp">
                      <button
                        type="button"
                        className="botonVolDisp"
                        onClick={handleAndroid}
                      >
                        Tengo Android
                      </button>
                    </div>
                  </div>
                  <div className="col-5 contSegCel">
                    <img
                      src={require("../assets/Iphone/Iphone 00.png")}
                      className="celusUno"
                      onClick={handleIphone}
                    />
                    <div className="col-12 contBtnDispDo">
                      <button
                        type="button"
                        className="botonVolDisp"
                        onClick={handleIphone}
                      >
                        Tengo Iphone
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {androidIntruc && (
            <div className="row contGeneralIntruc">
              <div className="col-12">
                <CarrucelAndroid />
              </div>
              <div className="col-12 contBtnVolEle">
                <button
                  type="button"
                  className="botonVolEle"
                  onClick={handleDispositivos}
                >
                  Volver a Elegir Dispositivo
                </button>
              </div>
            </div>
          )}
          {iphoneIntruc && (
            <div className="row contGeneralIntruc">
              <div className="col-12">
                <CarrucelIphone />
              </div>
              <div className="col-12 contBtnVolEle">
                <button
                  type="button"
                  className="botonVolEle"
                  onClick={handleDispositivos}
                >
                  Volver a Elegir Dispositivo
                </button>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default InstructivoIntalacion;
