import { useEffect } from "react";
import axios from "axios";



const urlApiDolarBlue = "https://api.bluelytics.com.ar/v2/latest";

const ValDolarBlue = () => {

  const apiDolarBlu = async (e) => {
    try {
      await axios
        .get(urlApiDolarBlue)
        .then((res) => res.data.blue)
        .then((res) => mostrarDatos(res));
    } catch (error) {}
  };
  useEffect(() => {
    apiDolarBlu();
  },[]);

  const mostrarDatos = (data) => {
    //console.log(data)
    let valorDolarBlue = "";
    let valDolarBlue = "";
    valorDolarBlue += `${data.value_sell}`;
    valDolarBlue = new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: "ARS",
    }).format(valorDolarBlue);
    return (document.getElementById(
      "valDolarBlu"
    ).innerHTML = `${valDolarBlue}`);
    //console.log(body)
  };
};

export default ValDolarBlue;
