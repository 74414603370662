import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "../css/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const CarrucelIphone = () => {
  const handleOnDragStart = (e) => e.preventDefault();

  const items = [
    <div className="item" key="1">
      <div className="row">
        <div className="col-12 col-xl-7 col-lg-7 col-sm-7">
            <div className="row contTxtCarrucel">
                <div className="col-12 contPrimerTxx">
                    <span>-Abrí la web de <span className="txtNaranja">ZH Inversiones </span>
                    desde el navegador Safari.
                    </span>
                </div>
                <div className="col-12 ">
                    <span>- Presioná el botón de <span className="txtNaranja">COMPARTIR</span> que se encuentra al lado de la dirección del sitio web</span>
                </div>
            </div>
        </div>
        <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contImgImgIntruc">
          <img
            src={require("../assets/Iphone/Iphone 01.png")}
            alt="imagen 1"
            className="imgInstruc"
            onDragStart={handleOnDragStart}
          />
        </div>
      </div>
    </div>,
    <div className="item" key="2">
      <div className="row">
        <div className="col-12 col-xl-7 col-lg-7 col-sm-7">
            <div className="row contTxtCarrucel">
                <div className="col-12 contPrimerTxx">
                    <span>-Luego presionamos el botón que dice <span className="txtNaranja">AÑADIR A PANTALLA DE INICIO</span>
                    </span>
                </div>
            </div>
        </div>
        <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contImgImgIntruc">
          <img
            src={require("../assets/Iphone/Iphone 02.png")}
            alt="imagen 1"
            className="imgInstruc"
            onDragStart={handleOnDragStart}
          />
        </div>
      </div>
    </div>,
    <div className="item" key="3">
      <div className="row">
        <div className="col-12 col-xl-7 col-lg-7 col-sm-7">
            <div className="row contTxtCarrucelTres">
                <div className="col-12">
                    <span>-Presionamos <span className="txtNaranja">AGREGAR</span>
                    </span>
                </div>
            </div>
        </div>
        <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contImgImgIntruc">
          <img
            src={require("../assets/Iphone/Iphone 03.png")}
            alt="imagen 1"
            className="imgInstrucCua"
            onDragStart={handleOnDragStart}
          />
        </div>
      </div>
    </div>,
    <div className="item" key="4">
      <div className="row">
        <div className="col-12 col-xl-7 col-lg-7 col-sm-7">
            <div className="row contTxtCarrucel">
                <div className="col-12">
                    <span>-Luego de unos segundos se agregará en nuestra pantalla.
                    </span>
                </div>
            </div>
        </div>
        <div className="col-12 col-xl-5 col-lg-5 col-sm-5 contImgImgIntruc">
          <img
            src={require("../assets/Iphone/Iphone 04.png")}
            alt="imagen 1"
            className="imgInstruc"
            onDragStart={handleOnDragStart}
          />
        </div>
      </div>
    </div>
  ];

  const responsive = {
    0: { items: 1 },
    1024: { items: 1 },
  };

  return (
    <AliceCarousel
      mouseTracking
      items={items}
      responsive={responsive}
      autoPlay
      autoPlayInterval={30000000}
      showSlideInfo={false}
      slideToIndex={0}
      dotsDisabled={false}
      buttonsDisabled={false}
      infinite={true}
      renderPrevButton={({ isDisabled }) => (
        <button className="buttonPrevCel" disabled={isDisabled}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      )}
      renderNextButton={({ isDisabled }) => (
        <button className="buttonNextCel" disabled={isDisabled}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      )}
    />
  );
};

export default CarrucelIphone;