import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import Img from "../assets/logo-zuppa-hermanos-acopio.png";
import ImgLogoCarga from "../assets/logo_con_trazo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faArrowLeft,
  faFileInvoiceDollar,
  faArrowRightFromBracket,
  faCircleDown,
  faBars,
  faClockRotateLeft,
  faPenToSquare,
  faBell,
  faGem,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import ImgFondo from "../assets/Dark_background_1920x1080.png";
//import MixedChart from "../components/grafico";
import "../css/style.css";
import axios from "axios";
import "styled-components";
import CarrucelExp from "../components/CarrucelExp";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase";
import ArtiBotComponent from "../components/chatbot/ArtiBotComponet";
import getFecha from "../Apis/fecha";
import Tabla from "../components/Tabla";
import TablaInterAnual from "../components/TablaInterAnual";
import TablaIndec from "../components/TablaIdec";

const backgroundImageMain = {
  backgroundImage: `url(${ImgFondo})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  width: "100%",
  height: "100%",
};
const DATOS_ACOPIO = "https://www.zuppahermanos.com.ar/virtual/api/acopio.php";

/* const DATOS_COTIZ =
  "https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerSeriesVariacionesAcumuladoAnual";

const DATOS_COTIZ_MES =
  "https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerSeriesVariacionesAcumuladoAnual&tipo=mes"; */
const obtenerUrl = () => {
  const ini = getFecha("FCORTAREV", "-"); // Fecha actual
  const fin = getFecha("FCORTAREV", "-");
  const url = `https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerSeriesEstadisticasZHInversiones&ano_dd=${
    ini.split("-")[0]
  }&mes_dd=1&dia_dd=1&ano_hh=${fin.split("-")[0]}&mes_hh=${
    fin.split("-")[1]
  }&dia_hh=${fin.split("-")[2]}`;

  return url;
};
const obtenerUrlDos = () => {
  const ini = getFecha("FCORTAREV", "-"); // Fecha actual
  const fin = getFecha("FCORTAREV", "-");
  const url = `https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerSeriesEstadisticasZHInversiones&ano_dd=${
    ini.split("-")[0]
  }&mes_dd=${ini.split("-")[1]}&dia_dd=1&ano_hh=${fin.split("-")[0]}&mes_hh=${
    fin.split("-")[1]
  }&dia_hh=${fin.split("-")[2]}`;

  return url;
};

const obtenerUrlCuatro = () => {
  const ini = getFecha("FCORTAREV", "-"); // Fecha actual

  const url = `https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerSeriesEstadisticasInterAnualZHInversiones&ano_dd=${
    ini.split("-")[0] - 1
  }&mes_dd=${ini.split("-")[1]}&dia_dd=${ini.split("-")[2]}&ano_hh=${
    ini.split("-")[0]
  }&mes_hh=${ini.split("-")[1]}&dia_hh=${ini.split("-")[2]}`;
  return url;
};

const REFRESH_TOKEN =
  "https://www.zuppahermanos.com.ar/virtual/api/refreshTokenCliente.php";
const TOKEN_NOTI = "https://www.zuppahermanos.com.ar/virtual/api/acopio.php";

const Grafico = () => {
  const anoActual = new Date().getFullYear();
  const [anoSeleccionado, setAnoSeleccionado] = useState(anoActual - 1);
  const [anoSeleccionadoDos, setAnoSeleccionadoDos] = useState(anoActual);
  const [anoSeleccionadoTres, setAnoSeleccionadoTres] = useState(anoActual);
  const [urlTres, setUrlTres] = useState("");
  const [urlCinco, setUrlCinco] = useState("");
  const [urlSeis, setUrlSeis] = useState("");
  const [dataUno, setDataUno] = useState("");
  const [dataDos, setDataDos] = useState("");
  const [dataTres, setDataTres] = useState("");
  const [dataCuatro, setDataCuatro] = useState("");
  const [dataCinco, setDataCinco] = useState("");
  const [dataSeis, setDataSeis] = useState("");
  const [columnaUno, setColumnaUno] = useState("");
  const [columnaDos, setColumnaDos] = useState("");
  const [columnaTres, setColumnaTres] = useState("");
  const [columnaCuatro, setColumnaCuatro] = useState("");
  const [columnaCinco, setColumnaCinco] = useState("");
  const [tablaUnoVisible, setTablaUnoVisible] = useState(true);
  const [tablaDosVisible, setTablaDosVisible] = useState(true);
  const [tablaTresVisible, setTablaTresVisible] = useState(true);
  const [tablaCuatroVisible, setTablaCuatroVisible] = useState(true);
  const [tablaCincoVisible, setTablaCincoVisible] = useState(true);
  const [tablaSeisVisible, setTablaSeisVisible] = useState(true);
  const [opcionesComboRendimiento, setOpcionesComboRendimiento] = useState([]);
  const [opcionesComboRendimientoIndec, setOpcionesComboRendimientoIndec] =
    useState([]);
  const [opcionesComboAnoRendimiento, setOpcionesComboAnoRendimiento] =
    useState([]);
  const [isReadyForInstall, setIsReadyForInstall] = React.useState(false);
  const [nomUser, setNomUser] = useState("");
  const [isReadyForCarga, setIsReadyForCarga] = useState(true);
  const [isReadyForNotification, setIsReadyForNotification] = useState(false);
  const [expUnica, setExpUnica] = useState(false);

  // Función para actualizar la visibilidad de las tablas según los datos recibidos
  const actualizarVisibilidadTablas = (datos) => {
    const actualizaciones = datos.map((item) => ({
      tipo: item.tipo,
      valor: parseInt(item.valor) !== 1, // Invierte la lógica aquí
    }));

    // Actualizar el estado de visibilidad basado en las actualizaciones
    actualizaciones.forEach((item) => {
      switch (item.tipo) {
        case "oculta_vari_anual_zhinv":
          setTablaUnoVisible(item.valor);
          break;
        case "oculta_vari_mensual_zhinv":
          setTablaDosVisible(item.valor);
          break;
        case "oculta_rendimiento_zhinv":
          setTablaTresVisible(item.valor);
          break;
        case "oculta_vari_interanual_zhinv":
          setTablaCuatroVisible(item.valor);
          break;
        case "oculta_vari_mensual_zucom_zhinv":
          setTablaCincoVisible(item.valor);
          break;
        case "oculta_vari_mensual_indec_zhinv":
          setTablaSeisVisible(item.valor);
          break;
        // Agrega más casos según sea necesario
        default:
          break;
      }
    });
  };

  const url = obtenerUrl();
  const urlDos = obtenerUrlDos();
  const urlCuatro = obtenerUrlCuatro();

  const urlMostrar =
    "https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerParametrosMuetraGrillasEstadisticas";

  const datosMostrar = async (e) => {
    try {
      const response = await axios.get(urlMostrar);
      actualizarVisibilidadTablas(response.data.message);
    } catch (error) {
      console.error("Error en la llamada a la API:", error);
    }
  };

  const datosVar = async (e) => {
    try {
      const response = await axios.get(url);

      setDataUno(response.data.message.SERIES);
      setColumnaUno(response.data.message);
    } catch (error) {
      console.error("Error en la llamada a la API:", error);
    }
  };
  const datosVarDos = async (e) => {
    try {
      const response = await axios.get(urlDos);

      setDataDos(response.data.message.SERIES);
      setColumnaDos(response.data.message);
    } catch (error) {}
  };
  const datosVarCuatro = async (e) => {
    try {
      const response = await axios.get(urlCuatro);
      const datosFiltrados = response.data.message.SERIES.map((item) => {
        // Verificar si las propiedades existen y son números
        const nombre = item.Nombre || "Nombre no disponible";
        const variacion =
          typeof item.Variacion === "number" ? item.Variacion : 0;

        return {
          Nombre: nombre,
          Variacion: variacion,
        };
      });

      setDataCuatro(datosFiltrados);
      setColumnaCuatro(response.data.message);
    } catch (error) {
      console.error("Error en la llamada a la API:", error);
    }
  };
  const datosVarTres = async (url) => {
    try {
      const response = await axios.get(url);
      if (response.data.message === null) {
        setDataTres("");
        setColumnaTres("");
      } else {
        setDataTres(response.data.message.SERIES);
        setColumnaTres(response.data.message);
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
    }
  };
  const datosVarCinco = async (url) => {
    try {
      const response = await axios.get(url);
      if (response.data.message === null) {
        setDataCinco("");
        setColumnaCinco("");
      } else {
        // Filtrar los datos con variación diferente de 0
        const datosFiltrados = response.data.message.SERIES.filter(
          (item) => item.Variacion !== 0
        );

        setDataCinco(datosFiltrados);
        setColumnaCinco(response.data.message);
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
    }
  };
  const datosVarSeis = async (url) => {
    try {
      const response = await axios.get(url);
      const datosFiltrados = response.data.message;

      if (response.data.message === null) {
        setDataSeis("");
      } else {
          setDataSeis(datosFiltrados);
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    // Construye la URL con las fechas y el año seleccionado
    const nuevaUrl = `https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerSeriesEstadisticasZHInversiones&ano_dd=${anoSeleccionado}&mes_dd=1&dia_dd=1&ano_hh=${anoSeleccionado}&mes_hh=12&dia_hh=31`;

    // Actualiza el estado de la URL
    setUrlTres(nuevaUrl);

    // Ejecuta la función para obtener datos
    datosVarTres(nuevaUrl);
  }, [anoSeleccionado]); // Se ejecuta cada vez que cambia anoSeleccionado
  useEffect(() => {
    // Construye la URL con las fechas y el año seleccionado
    const nuevaUrlDos = `https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerSeriesEstadisticasVariacionMensualZucomZHInversiones&ano=${anoSeleccionadoDos}`;

    // Actualiza el estado de la URL
    setUrlCinco(nuevaUrlDos);
    // Ejecuta la función para obtener datos
    datosVarCinco(nuevaUrlDos);
  }, [anoSeleccionadoDos]); // Se ejecuta cada vez que cambia anoSeleccionado
  useEffect(() => {
    // Construye la URL con las fechas y el año seleccionado
    const nuevaUrlTres = `https://www.zuppahermanos.com.ar/virtual/api/generico.php?accion=obtenerSeriesEstadisticasVariacionMensualIndecZHInversiones&ano=${anoSeleccionadoTres}`;

    // Actualiza el estado de la URL
    setUrlSeis(nuevaUrlTres);
    // Ejecuta la función para obtener datos
    datosVarSeis(nuevaUrlTres);
  }, [anoSeleccionadoTres]); // Se ejecuta cada vez que cambia anoSeleccionado

  const cargaComboAnoRendimiento = () => {
    const options = [];
    for (let ii = anoActual - 1; ii >= 2023; ii--) {
      options.push(
        <option key={ii} value={ii}>
          {ii}
        </option>
      );
    }

    setOpcionesComboAnoRendimiento(options);
  };

  const cargaComboRendimiento = () => {
    const options = [];
    for (let ii = anoActual; ii >= 2023; ii--) {
      options.push(
        <option key={ii} value={ii}>
          {ii}
        </option>
      );
    }

    // Actualiza el estado con las opciones del combo
    setOpcionesComboRendimiento(options);
  };
  const cargaComboRendimientoIndec = () => {
    const options = [];
    for (let ii = anoActual; ii >= 2023; ii--) {
      options.push(
        <option key={ii} value={ii}>
          {ii}
        </option>
      );
    }

    // Actualiza el estado con las opciones del combo
    setOpcionesComboRendimientoIndec(options);
  };

  const handleAnoChange = (event) => {
    // Actualiza el estado de anoSeleccionado cuando cambia el valor del select
    setAnoSeleccionado(parseInt(event.target.value, 10));
  };
  const handleAnoChangeDos = (event) => {
    // Actualiza el estado de anoSeleccionado cuando cambia el valor del select
    setAnoSeleccionadoDos(parseInt(event.target.value, 10));
  };
  const handleAnoChangeTres = (event) => {
    // Actualiza el estado de anoSeleccionado cuando cambia el valor del select
    setAnoSeleccionadoTres(parseInt(event.target.value, 10));
  };
  const columnasUno = columnaUno
    ? [
        " ",
        `${columnaUno.MES_DESDE}-${columnaUno.ANO_DESDE}`,
        "Actual",
        "Variación",
      ]
    : [];
  const columnasDos = columnaDos
    ? [
        " ",
        `${columnaDos.MES_DESDE}-${columnaDos.ANO_DESDE}`,
        "Actual",
        "Variación",
      ]
    : [];
  const columnasTres = columnaTres
    ? [" ", `${columnaTres.MES_DESDE}`, `${columnaTres.MES_HASTA}`, "Variación"]
    : [];
  const columnasCuatro = columnaCuatro
    ? [{ Titulo: `${columnaCuatro.TITULO}`, Varia: "Variación" }]
    : [];
  const columnasCinco = columnaCinco
    ? [{ Titulo: "", Varia: "Variación" }]
    : [];
  const columnasSeis = [
    `Año ${anoSeleccionadoTres}`,
    "IPC Mensual",
    "IPC Acumulado",
    "IPC Interanual",
  ];
  useEffect(() => {
    datosVar();
    datosVarDos();
    datosVarTres(urlTres);
    datosVarCinco(urlCinco);
    datosVarSeis(urlSeis);
    datosMostrar();
    datosVarCuatro();
  }, []);

  useEffect(() => {
    datosVarTres(urlTres);
  }, [urlTres]);
  useEffect(() => {
    datosVarCinco(urlCinco);
  }, [urlCinco]);
  useEffect(() => {
    datosVarSeis(urlSeis);
  }, [urlSeis]);
  useEffect(() => {
    cargaComboAnoRendimiento();
    cargaComboRendimiento();
    cargaComboRendimientoIndec();
  }, []); // Se ejecuta solo una vez al montar el componente

  const Logout = () => {
    localStorage.removeItem("userDataAcopio");
    localStorage.removeItem("token");
    localStorage.removeItem("cod_zh");
    localStorage.removeItem("cod_sinc");
    localStorage.removeItem("nombre");
    localStorage.removeItem("apellido");
    window.location.href = "/";
  };

  const refreshToken = async (e) => {
    let token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        REFRESH_TOKEN,
        JSON.stringify({
          token: token,
        }),
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (res.data.status === "true") {
        let tooken = res.data.token;
        localStorage.setItem("token", tooken);
      } else if (res.data.status === "false") {
        localStorage.setItem("token", "");
        window.location.href = "./";
      }
    } catch (error) {}
  };

  const saludoCliente = () => {
    let nom = localStorage.getItem("nombre");
    setNomUser(nom);
  };

  const componentDidMount = () => {
    if (!localStorage.getItem("token")) {
      window.location.href = "./";
    }
  };
  /* Funcion para mandar el token de firebase a la base de datos*/

  const mandarToken = async (e) => {
    let tokenCli = localStorage.getItem("token");
    let tokenNot = localStorage.getItem("tokenNoti");
    let codClie = localStorage.getItem("cod_zh");
    try {
      await axios
        .post(
          TOKEN_NOTI,
          JSON.stringify({
            accion: "actualizaTokenFireBase",
            token: tokenCli,
            cod_cli: codClie,
            token_firebase_notif: tokenNot,
          }),
          {
            headers: {
              "Content-type": "text/html; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "false" ||res.data.id_message === "905" || res.data.id_message === "900" ) {
              refreshToken();
            }
          }
        });
    } catch (err) {}
  };

  const activarMensajes = async () => {
    const token = await getToken(messaging, {
      vapidKey:
        "BMWw5bjl1wRMnNJaum67X33pUrUGwp3Dn89G5h8Y2ZlCQPWg4ayO4z-I3TX-sSXKWB9zNuWOQV20cy7bP6wPydk",
    }).catch((error) => error);

    if (token) localStorage.setItem("tokenNoti", token);
    if (!token) ;
  };

  /* Funciones para verificar si hay un token de notificacion existente dentro del localstorage y de la base de datos */

  const verificacionTokenNoti = () => {
    let tokensFire = JSON.parse(localStorage.getItem("tokenFirebase"));
    let tokenNoti = localStorage.getItem("tokenNoti");
    let existeEnBD = false;

    for (var i in tokensFire) {
      let con = tokensFire[i];
      if (con.token_firebase_notif === tokenNoti) {
        existeEnBD = true;
      }
      if (existeEnBD) {
        break;
      }
    }

    if (existeEnBD === true) {
      setIsReadyForNotification(false);
    } else if (existeEnBD === false) {
      setIsReadyForNotification(true);
    }
  };

  /* Funcion para preguntar todos los dias si el usuario desea activar las notificaciones, este mensaje
se deja de preguntar cuando el usuario activa las notificaciones, las bloquea o su dispositivo no las admite
*/

  const mensajeDiario = () => {
    let fechaActual = new Date();
    let _diaActual = fechaActual.toLocaleDateString();
    if (!("Notification" in window)) {
    } else if ("Notification" in window) {
      if (localStorage.getItem("tokenNoti") === null) {
        if (localStorage.getItem("fechaNoti") === null) {
          setTimeout(() => {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              title: "¿Activar Notificaciones?",
              text: "Te notificaremos movimientos en tu cuenta y cambios de la aplicacion.",
              showDenyButton: true,
              denyButtonColor: "#666666",
              confirmButtonText: "Activar",
              confirmButtonColor: "#E85217",
              denyButtonText: `Cancelar`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Comprobamos si los permisos han sido concedidos anteriormente
                if (Notification.permission === "granted") {
                  // Si es correcto, lanzamos una notificación
                }

                // Si no, pedimos permiso para la notificación
                else if (Notification.permission !== "denied") {
                  Notification.requestPermission().then(function (permission) {
                    // Si el usuario nos lo concede, creamos la notificación
                    if (permission === "granted") {
                      // Si es correcto, lanzamos una notificación
                      activarMensajes();
                      Swal.fire({
                        width: "20em",
                        color: "#fff",
                        background: "#2B2B2B",
                        position: "center",
                        icon: "success",
                        title: "Notificaciones Activadas",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  });
                }

                // Por último, si el usuario ha denegado el permiso,
                // y quiere ser respetuoso, no hay necesidad de molestarlo.
              } else {
                let fecha = new Date();
                let _dia = fecha.toLocaleDateString();
                localStorage.setItem("fechaNoti", _dia);
              }
            });
          }, 2700);
        } else if (localStorage.getItem("fechaNoti") < _diaActual) {
          setTimeout(() => {
            Swal.fire({
              width: "20em",
              color: "#fff",
              background: "#2B2B2B",
              title: "¿Activar Notificaciones?",
              text: "Te notificaremos movimientos en tu cuenta y cambios de la aplicacion.",
              showDenyButton: true,
              denyButtonColor: "#666666",
              confirmButtonText: "Activar",
              confirmButtonColor: "#E85217",
              denyButtonText: `Cancelar`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // Comprobamos si los permisos han sido concedidos anteriormente
                if (Notification.permission === "granted") {
                  // Si es correcto, lanzamos una notificación
                }

                // Si no, pedimos permiso para la notificación
                else if (Notification.permission !== "denied") {
                  Notification.requestPermission().then(function (permission) {
                    // Si el usuario nos lo concede, creamos la notificación
                    if (permission === "granted") {
                      // Si es correcto, lanzamos una notificación
                      activarMensajes();
                      Swal.fire({
                        width: "20em",
                        color: "#fff",
                        background: "#2B2B2B",
                        position: "center",
                        icon: "success",
                        title: "Notificaciones Activadas",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                    }
                  });
                }

                // Por último, si el usuario ha denegado el permiso,
                // y quiere ser respetuoso, no hay necesidad de molestarlo.
              } else {
                let fecha = new Date();
                let _dia = fecha.toLocaleDateString();
                localStorage.setItem("fechaNoti", _dia);
              }
            });
          }, 2700);
        }
      } else if (localStorage.getItem("tokenNoti") !== null) {
      }
    }
  };

  const notifyMe = () => {
    Swal.fire({
      width: "20em",
      color: "#fff",
      background: "#2B2B2B",
      title: "¿Activar Notificaciones?",
      text: "Te notificaremos movimientos en tu cuenta y cambios de la aplicacion.",
      showDenyButton: true,
      denyButtonColor: "#666666",
      confirmButtonText: "Activar",
      confirmButtonColor: "#E85217",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Comprobamos si los permisos han sido concedidos anteriormente
        if (Notification.permission === "granted") {
          // Si es correcto, lanzamos una notificación
        }

        // Si no, pedimos permiso para la notificación
        else if (Notification.permission !== "denied") {
          Notification.requestPermission().then(function (permission) {
            // Si el usuario nos lo concede, creamos la notificación
            if (permission === "granted") {
              // Si es correcto, lanzamos una notificación
              activarMensajes();
              Swal.fire({
                width: "20em",
                color: "#fff",
                background: "#2B2B2B",
                position: "center",
                icon: "success",
                title: "Notificaciones Activadas",
                showConfirmButton: false,
                timer: 1500,
              });
              setIsReadyForNotification(false);
              mandarToken();
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);
  async function downloadApp() {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    const result = await promptEvent.userChoice;
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    setIsReadyForInstall(false);
  }
  const handleInicio = (e) => {
    e.preventDefault();
    window.location.href = "/";
  };
  const handleGrilla = (e) => {
    e.preventDefault();
    window.location.href = "/movimientos";
  };
  const handleHistorial = (e) => {
    e.preventDefault();
    window.location.href = "/historialcotiz";
  };
  const handleCambiarPass = (e) => {
    e.preventDefault();
    window.location.href = "/resetearcontrasena";
  };

  useEffect(() => {
    saludoCliente();
    setTimeout(() => {
      setIsReadyForCarga(false);
    }, 1370);
  }, []);

  useEffect(() => {
    componentDidMount();
    verificacionTokenNoti();
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        setIsReadyForNotification(false);
      }
    }
    comprobarNoti();
  }, []);

  const haldleCerrarExp = (e) => {
    e.preventDefault();
    setExpUnica(false);
    localStorage.setItem("expZucom", "vista");
  };

  const handleMostrarExp = (e) => {
    e.preventDefault();
    setExpUnica(true);
  };
  const handleInstructivo = (e) => {
    e.preventDefault();
    window.location.href = "./instructivoinstalacion";
  };

  const comprobarNoti = () => {
    if ("Notification" in window) {
      if (Notification.permission === "default") {
        setIsReadyForNotification(true);
        mensajeDiario();
      }
      if (Notification.permission === "granted") {
        activarMensajes();
        setIsReadyForNotification(false);
        mandarToken();
      }
      if (Notification.permission === "denied") {
        setIsReadyForNotification(false);
      }
    } else if (!("Notification" in window)) {
      setIsReadyForNotification(false);
    }
  };
  const [iniConDatos, setIniConDatos] = useState(true);
  const [botTrue, setBotTrue] = useState(false);
  useEffect(() => {
    if (
      localStorage.getItem("userDataAcopio") === "1001" ||
      (localStorage.getItem("dniCliente") === "99887766" &&
        iniConDatos === true)
    ) {
      setBotTrue(true);
    }
    const currentURL = new URL(window.location.href);
    const codigoZh = localStorage.getItem("cod_sinc");
    const nombreZh = localStorage.getItem("nombre_sol");
    const apellidoZh = localStorage.getItem("apellido");
    if (iniConDatos === true) {
      currentURL.searchParams.set(
        "i",
        codigoZh + "_" + nombreZh + "_" + apellidoZh
      );
      window.history.pushState({ path: currentURL.href }, "", currentURL.href);
    }
  }, [iniConDatos]);

  return (
    <body>
      {isReadyForCarga && (
        <div className="contHijo">
          <div className="divContenedorHijo">
            <div className="contenedor-loader">
              <div>
                <img src={ImgLogoCarga} className="loader1" alt="log carga" />
              </div>
            </div>
            <div className="conTxt">
              <span className="txtLoad">ZH Inversiones</span>
            </div>
          </div>
        </div>
      )}
      {expUnica && (
        <div className="contenedorExp">
          <div className="row contExp">
            <div className="contSalirExp">
              <FontAwesomeIcon icon={faXmark} onClick={haldleCerrarExp} />
            </div>
            <div className="contImgExp">
              <CarrucelExp />
            </div>
            <div className="col-12 contBtnOmitir">
              <button className="buttonOmitirExp" onClick={haldleCerrarExp}>
                OMITIR
              </button>
            </div>
          </div>
        </div>
      )}
      <header className="header-inicio">
        <div className="conteiner-fluid">
          <div className="row" style={{ backgroundColor: "#E85217" }}>
            <div className="col-12" style={{ padding: 8 }}></div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-10 col-lg-10 col-md-8 col-sm-5 col-6">
            <img src={Img} className="img-logo" alt="logo de acopio" />
          </div>
          <div className="col-xl-2 col-lg-2 col-md-4 col-sm-5 col-6">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-dark-example1" variant="light">
                <FontAwesomeIcon icon={faBars} className="colorIcono" />
                <span className="txtDropdown">MENU</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="menuDropdown" variant="dark">
                <Dropdown.Header
                  style={{ color: "#fff" }}
                  className="contNomUser"
                >
                  <FontAwesomeIcon icon={faUserTie} className="iconoUser" />
                  <span className="txtNom">{nomUser}</span>
                </Dropdown.Header>
                <Dropdown.Divider />
                <Dropdown.Item
                  type="button"
                  style={{ color: "#fff" }}
                  onClick={handleCambiarPass}
                >
                  <FontAwesomeIcon icon={faPenToSquare} className="iconoMov" />
                  <span className="txtMov">Cambiar Contraseña</span>
                </Dropdown.Item>
                {isReadyForInstall && (
                  <Dropdown.Item
                    type="button"
                    style={{ color: "#fff" }}
                    onClick={downloadApp}
                  >
                    <FontAwesomeIcon
                      icon={faCircleDown}
                      className="iconoCerrar"
                    />
                    <span>Descargar</span>
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  type="button"
                  style={{ color: "#fff" }}
                  onClick={handleGrilla}
                >
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    className="iconoMov"
                  />
                  <span className="txtMov">Movimientos</span>
                </Dropdown.Item>
                <Dropdown.Item
                  type="button"
                  style={{ color: "#fff" }}
                  onClick={handleHistorial}
                >
                  <FontAwesomeIcon
                    icon={faClockRotateLeft}
                    className="iconoMov"
                  />
                  <span className="txtMov">Historial ZUCOM</span>
                </Dropdown.Item>
                <Dropdown.Item
                  type="button"
                  style={{ color: "#fff" }}
                  onClick={handleInicio}
                >
                  <FontAwesomeIcon icon={faArrowLeft} className="iconoMov" />
                  <span className="txtMov">Volver al Inicio</span>
                </Dropdown.Item>
                {isReadyForNotification && (
                  <Dropdown.Item
                    type="button"
                    style={{ color: "#fff" }}
                    onClick={notifyMe}
                  >
                    <FontAwesomeIcon icon={faBell} className="iconoNot" />
                    <span className="txtMov">Activar Notificaciones</span>
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  type="button"
                  style={{ color: "#fff" }}
                  onClick={handleMostrarExp}
                >
                  <FontAwesomeIcon icon={faGem} className="iconoMov" />
                  <span className="txtExpZucom">¿Qué es el ZUCOM?</span>
                </Dropdown.Item>
                {isReadyForInstall && (
                  <Dropdown.Item type="button" style={{ color: "#fff" }}>
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      className="iconoMov"
                    />
                    <span className="txtInstruc" onClick={handleInstructivo}>
                      Instructivo de Instalación
                    </span>
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  type="button"
                  style={{ color: "#fff" }}
                  onClick={Logout}
                >
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    className="iconoCerrar"
                  />
                  <span>Cerrar Sesión</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </header>
      <main style={backgroundImageMain} className="contGrafico">
        {botTrue && <ArtiBotComponent />}
        <div className="tituloMov">
          <span className="titulomovimiento">ESTADÍSTICAS</span>
        </div>
        {tablaCuatroVisible && (
          <>
            <div className="contTituloGrillaAnual">
              <h2 className="tituloGrillaAnual">
                Variación Interanual de Inversiones
              </h2>
            </div>
            <TablaInterAnual columns={columnasCuatro} datosCotiz={dataCuatro} />
          </>
        )}
        {tablaUnoVisible && (
          <>
            <div className="contTituloGrillaAnual">
              <h2 className="tituloGrillaAnual">
                Variación Anual de Inversiones
              </h2>
            </div>
            <Tabla columns={columnasUno} datosCotiz={dataUno} />
          </>
        )}
        {tablaDosVisible && (
          <>
            <div className="contTituloGrillaAnual">
              <h2 className="tituloGrillaAnual">
                Variación Mensual de Inversiones
              </h2>
            </div>
            <Tabla columns={columnasDos} datosCotiz={dataDos} />
          </>
        )}

        {/* <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-7 col-md-8 col-sm-10 col-12 contenedorVarGrafico">
                        <div>
                            <MixedChart />
                        </div>
                    </div>
                </div> */}
        {tablaTresVisible && (
          <>
            <div className="row contTituloGrillaRendimiento">
              <div className="col-md-7 col-8 contHdosRendimiento">
                <h2 className="tituloGrillaRemdimiento">
                  Rendimiento Año {anoSeleccionado}
                </h2>
              </div>
              <div className="col-md-4 col-3 contSelectRendimiento">
                <select
                  className="selectRendimiento"
                  value={anoSeleccionado}
                  onChange={handleAnoChange}
                >
                  {opcionesComboAnoRendimiento}
                </select>
              </div>
            </div>
            <Tabla columns={columnasTres} datosCotiz={dataTres} />
          </>
        )}
        {tablaCincoVisible && (
          <>
            <div className="row contTituloGrillaRendimiento">
              <div className="col-md-7 col-9 contHdosRendimiento">
                <h2 className="tituloGrillaRemdimiento">
                  Variación Mensual del ZUCOM en {anoSeleccionadoDos}
                </h2>
              </div>
              <div className="col-md-4 col-3 contSelectRendimiento">
                <select
                  className="selectRendimiento"
                  value={anoSeleccionadoDos}
                  onChange={handleAnoChangeDos}
                >
                  {opcionesComboRendimiento}
                </select>
              </div>
            </div>
            <TablaInterAnual columns={columnasCinco} datosCotiz={dataCinco} />
          </>
        )}
        {tablaSeisVisible && (
          <>
            <div className="row contTituloGrillaRendimiento">
              <div className="col-md-8 col-9 contHdosRendimiento">
                <h2 className="tituloGrillaRemdimiento">
                  INDEC (Índice de Precios al Consumidor)
                </h2>
              </div>
              <div className="col-md-4 col-3 contSelectRendimientoIndec">
                <select
                  className="selectRendimiento"
                  value={anoSeleccionadoTres}
                  onChange={handleAnoChangeTres}
                >
                  {opcionesComboRendimientoIndec}
                </select>
              </div>
            </div>
            <TablaIndec columns={columnasSeis} datosCotiz={dataSeis} />
            <div className="contTxtIndice">
              <span className="txtBolsa">
                Valor de referencia de la página web del{" "}
                <a
                  className="aIndec"
                  href="https://www.indec.gob.ar/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instituto Nacional de Estadísticas y Censos.
                </a>
              </span>
            </div>
          </>
        )}
        <div className="row contenedorVolverEstadisticas">
          <div className="col-12 containerMov">
            <a className="colorBlan" href="/" onClick={handleInicio}>
              <FontAwesomeIcon icon={faArrowLeft} className="iconoMov" />
              {/* <FontAwesomeIcon icon={faArrowLeft} className="iconoMov" /> */}
              <span className="txtMov">Volver al Inicio</span>
            </a>
          </div>
        </div>
      </main>
    </body>
  );
};

export default Grafico;
